import './App.scss';
import React, {useState} from 'react';
import {HashRouter, Link, Route, Switch} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Pages/AboutUs/AboutUs";
import PrivacyPage from "./Pages/Privacy/PrivacyPage";
import PrivacyBottom from "./Pages/PrivacyBottom/PrivacyBottom";
import UpOutlined from "@ant-design/icons/lib/icons/UpOutlined";
import Financing from "./Pages/Financing/Financing";
import AML from "./Pages/AML/aml";
import GDPR from "./Pages/GDPR/GDPR";
import WhitePapers from "./Pages/WhitePapers/WhitePapers";
import BusinessPlan from "./Pages/BusinessPlan/BusinessPlan";
import CompanyRegistration from "./Pages/CompanyRegistration/CompanyRegistration";
import Terms from "./Pages/Terms/Terms";
import PowerAttorney from "./Pages/PowerAttorney/PowerAttorney";
import PrivatePlacement from "./Pages/PrivatePlacement/PrivatePlacement";
import Saft from "./Pages/Saft/Saft";
import Accounting from "./Pages/Accounting/Accounting";
import Contact from "./Pages/Contact/contact";
import Registration from "./Pages/Registration/Registration";
import Artist from "./Pages/Artist/Artist";
import Freelacencers from "./Pages/Freelacencers/Freelacencers";
import Blog from "./Pages/Blog/Blog";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import Franchise from "./Pages/Franchise/Franchise";
import articleRoutes from './ArticleRoutes';

const App = () => {
    const [showToTopBtn, setShowToTopBtn] = useState(false);

    window.addEventListener('scroll', () => {
        const scrollHeight = window.pageYOffset;

        if (scrollHeight >= 200) {
            setShowToTopBtn(true)
        } else {
            setShowToTopBtn(false)
        }
    })

    return (
        <div className='main-wrapper'>
            <HashRouter>
                <Header/>
                <div className='main-wrapper__barrier'/>
                <Switch>
                    <Route exact path='/'>
                        <Home/>
                    </Route>
                    <Route exact path='/about'>
                        <AboutUs/>
                    </Route>
                    <Route exact path='/contact'>
                        <Contact/>
                    </Route>
                    <Route exact path='/privacy'>
                        <PrivacyPage/>
                    </Route>
                    <Route exact path='/codipityprivacypolicy'>
                        <PrivacyBottom/>
                    </Route>
                    <Route exact path='/partnerships'>
                        <Franchise/>
                    </Route>
                    <Route exact path='/financing'>
                        <Financing/>
                    </Route>
                    <Route exact path='/aml'>
                        <AML/>
                    </Route>
                    <Route exact path='/gdpr'>
                        <GDPR/>
                    </Route>
                    <Route exact path='/white-papers'>
                        <WhitePapers/>
                    </Route>
                    <Route exact path='/business-plan'>
                        <BusinessPlan/>
                    </Route>
                    <Route exact path='/company-registration'>
                        <CompanyRegistration/>
                    </Route>
                    <Route exact path='/power-attorney'>
                        <PowerAttorney/>
                    </Route>
                    <Route exact path='/private-placement'>
                        <PrivatePlacement/>
                    </Route>
                    <Route exact path='/saft'>
                        <Saft/>
                    </Route>
                    <Route exact path='/accounting'>
                        <Accounting/>
                    </Route>
                    <Route exact path='/registration'>
                        <Registration/>
                    </Route>
                    <Route exact path='/artist'>
                        <Artist/>
                    </Route>
                    <Route exact path='/freelacencers'>
                        <Freelacencers/>
                    </Route>
                    <Route exact path='/blog'>
                        <Blog/>
                    </Route>
                    <Route exact path='/terms'>
                        <Terms/>
                    </Route>
                    {articleRoutes.map((route) =>
                        <Route exact path={route.link}>
                            <route.component/>
                        </Route>)
                    }
                    <Route path='*/**'>
                        <PageNotFound/>
                    </Route>
                </Switch>
                <Footer/>
                {showToTopBtn &&
                <button className='main-wrapper__toTop-btn' onClick={() => window.scrollTo(0, 0)}>
                    <UpOutlined/>
                </button>}
            </HashRouter>
        </div>
    );
};

export default App;
