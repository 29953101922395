import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const Artist = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Artist Contracts
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>ARE YOU AN ARTIST LOOKING TO CROWDFUND?<br/>
                    If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p> Are you an artist looking to crowdfund? Are you selling rare digital items online, and don't understand the legal ramifications? Do you want to decentralize your music label or film company? 
                    In most cases, you should tokenize your company and register an equity crowdfunding campaign for your album, film, novel, game, technology or other creative endeavor, in an EU jurisdiction, using the fanbase.io portal. 
                    You'll need to incorporate a company and promote your crowdsale where it is legal to securitize such things.  
                    Currently, Fanbase is the official consultancy for fanbase.io, the world leader in equity crowdfunding for creative projects. 
                    We handle all of the creator, artist, developer, company and label contracts for users of the fanbase.io platform so that everything is compliant 
                    and you can concentrate on making a great media product. 
                    <ol className="privacy_text-ol">
                    
                    </ol>
                    To conduct such a crowdfund, the Lithuanian cryptocurrency license is generally the most inexpensive to acquire, 
                    and is the jurisdiction we recommend for most artists, production companies and labels, because you can legally tokenize, crowdfund and trade your token in the EU, 
                    South America, and most parts of Asia with the license. If you are in a hurry, Fanbase owns several existing licenses which are already prepared, with linked bank accounts, purchaseable by you.  
                    After your purchase, we go through several phases to decouple ourselves from the organization, but you can begin conducting business immediately. 
                    Generally we operate in the EU by Power of Attorney (PoA) and are required by law to open your accounts in person, unless you or another of the owners can fly to Europe. 
                    In most cases, the person working with your account is an attorney that has opened many accounts for clients and then later decoupled from those accounts.  </p>
                    <ol className="privacy_text-ol">
                    
                    </ol>
                    
                    <p>Alternatively, Fanbase can help you create your media company from scratch, but it will take an extra 30-60 days to do so.  
                    If you wish, you can still grant Fanbase a Power of Attorney to conduct business
                    for you while you wait for your e-registration ID. In some cases, you will need to e-register yourself and all members of the team, 
                    then pick up your documents at the Lithuanian embassy nearest you. It is important to reach out to us first, however, because in some cases, 
                    you may not need a cryptocurrency or ewallet license at all, and the process might be much easier.
                    <ol className="privacy_text-ol">
                    
                    </ol>
                    <p> If you are merely looking for DIY artist contracts for bands, singers, or perfomers of any kind, or contracts for film or television projects,
                        reach out to us at dex@fanbaseconsulting.com.  We have plenty on file that we share for free and we would love to talk about your project.</p>
                    
                    
                </p>
                <ol className="privacy_text-ol">
                    
                </ol>
                <p>Not interested in artist contracts or crowdfunding raises? We can help in other ways also.
                </p>
                <p><i><b>Contact us at <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help with a contract...'> dex@fanbaseconsulting.com</a> with any questions whatsoever. We help creators adapt in the era of fintech.</b></i></p>
            </div>

        </div>
    );
};

export default Artist;
