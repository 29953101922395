import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/tip3.jpg'
import ImageArticle01 from '../../../img/articles/tip3-1.png'
import ImageArticle02 from '../../../img/articles/tip3-2.jpg'


const ArticleTips3 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>TIP 3: UNDERSTANDING US MONEY TRANSMITTER LICENSES
                </h1>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <h3>JULY 16, 2020<br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com</a>
                </h3>
            </div>
            <div className="flex site-block__wrapper">
                <img src={ImageArticle} className="leftimg marginTop20"/>
                <p className='article-p-margin'>In the United States, a money transmitter is a business that enables
                    access to payment instruments, a financial entity that creates and manages money, or a currency
                    transfer service. Under federal law, a money transmitter falls under the purview of a US Money
                    Service Business (MSB). For MSBs, a federal license is issued by the Financial Crimes Enforcement
                    Network (FinCEN), of the United States Department of Treasury. Thereafter, most MSBs must acquire
                    Money Transmitter Licenses (MTLs) in each individual state and territory, which means that,
                    generally speaking, the task is herculean, and at times, downright maddening for entrepreneurs. In
                    all, it can take up to two years to complete. Clearly stated, MTLs are difficult to come by in the
                    US, and require a lot of legal wrangling. Crypto companies and e-money companies, in particular,
                    bear the brunt of this bureaucracy. Generally, it motivates US entrepreneurs, who would otherwise
                    choose a US jurisdiction, to first register companies abroad. The real losers are US taxpayers, and
                    the many schools, roads and bridges that could be subsidized with fintech taxes. Because of the
                    archaic way in which the US system is structured, US taxpayers lose millions each year in tax
                    revenues when US born entrepreneurs in the digital payments sector register companies in
                    pro-business jurisdictions like the EU. Eventually, these entrepreneurs might release their
                    technologies in the US, after launching their company elsewhere.
                </p>

            </div>
            <div className="privacy_flex-column site-block__wrapper margin-top-0">
                <p>But by doing so the lion’s share of
                    tax revenues go to that non-US jurisdiction. In some cases, the entrepreneurs are incorporated in,
                    and paying taxes to, a country they have never even visited. Most incubators and start-up hubs
                    understand that the next Facebook, Google, or Amazon may not be a US company, because the US is
                    failing to retain and recruit innovators. The truth is that while the US is floundering, small
                    countries in the EU like Lithuania are recruiting A-list entrepreneurs from premier start-up programs
                    and US Universities. Transferwise, a company registered in Lithuania, despite being a product of
                    Silicon Valley’s Y-Combinator start-up school, are a prime example of a billion dollar company that
                    opted for the EU, but may have stayed in the US, if the United States were friendly to businesses
                    operating as payment service providers.
                </p>



                <div className="flex site-block__wrapper marginTop20 column-wrap">
                    <img src={ImageArticle01} className=" leftimg marginTop20"/>
                    <div className='flex-column marginTop10'>
                    <h2>How did it get this way?</h2>
                        <p className="article-p-margin">In a bid to create a uniform system and consistent approach to the way
                            money services are carried
                            out, the Uniform Money Services Act was passed in 2000 as a guideline for MSBs. At the federal
                            level, registering an MSB is not difficult. It is in the states, where the problems lie. While it is
                            possible for the founder of an MSB to submit an application to the National Multi-State Licensing
                            system (NMLS) herself, most decide to hire a US-based attorney (at $200-$500 per hour) to
                            handle the extremely complex application process, because, on a scale of 1 to 10, the difficulty and
                            magnitude of the MTL process is a 9 or a 10. Each state has an application and a separate fee. State
                            fees will total somewhere between $100,000 and $250,000 and attorney fees will generally cost an
                            additional $300,000 or more. Unfortunately, most early stage start-ups do not have this kind of
                            money prior to having customers. On top of that, banks and trusts charge up to $10,000 per month for
                            a deposit account where payment service providers or e-money technologies can pool their funds,
                            although these costs vary, largely depend on the type of service a company provides, and rely on how
                            a technology operates. In no uncertain terms, to launch an e-money business in the United States is
                            next to impossible without funding or private wealth.

                        </p></div>
                </div>

                <p className="article-p-margin">To combat this nightmare, most entrepreneurs
                    choose to register their company, initially, in a more business friendly jurisdiction. Others opt
                    for more efficient processes in the US where they are exempt from MSB status, or they choose to
                    partner with a service provider like Fanbase, the global leader in corporate registrations and
                    consultations, to handle the state-by-state MTLs, at a flat-rate. Fanbase not only assists with the
                    registration process, but also helps companies document the flow of funds, explore license coverage,
                    and submit the necessary applications to each state. In essence, Fanbase helps entrepreneurs
                    navigate the entire Money Service Business process. To be clear, although Fanbase is registered in
                    the EU as a “Trust and Company Services” provider, the organization specifically offers its MTL
                    service from its US-based office, spearheaded by a US-licensed attorney. Fanbase clients therefore
                    receive discounted rates on US legal work. In some cases, Fanbase will conduct legal or
                    consultatory work in exchange for equity. Prior to using Fanbase, however, an entrepreneur should consider all
                    of the options at their disposal. Therefore, the following 6 strategies are recommended by Fanbase
                    for entrepreneurs seeking Money Transmitter Licenses. Start-ups can (1) affiliate; (2) become a
                    correspondent; (3) become an Independent Sales Organization; (4) enter an agent program; (5) own a
                    license; or, (6) become a banking agent.</p>


                <h2 style={{'marginTop': '20px'}}>Affiliate</h2>
                <p className="article-p-margin">As an affiliate, you are responsible for the sale of the product or
                    services on behalf of a license holder and you gain commission on each transaction you effect.
                    Basically, you are unlicensed, but can carry on business just like a person or business entity that
                    is licensed to do so. By law, an affiliate cannot access, disburse or spend any funds because the
                    client is actually that of the principal license holder. Hence, the principal license holder is
                    responsible for handling the funds. If you are a payments app that merely takes a transaction fee,
                    but does not have access to the sender accounts or the receiver accounts, you are generally
                    structured as an affiliate. Companies like Stripe, Dwolla, and Plaid facilitate these types of
                    white-label transactions for you, through the Buyer and Seller bank accounts. While you can take
                    transaction fees as an affiliate, your company is never in possession of the actual funds being
                    transferred. These are sometimes referred to as “white-label” relationships because your company
                    branding will remain on the service, but you are not actually conducting the transaction. Your
                    ability to utilize an affiliate relationship is a question of how you have designed your technology
                    to facilitate the flow of funds. </p>

                <h2 style={{'marginTop': '20px'}}>Correspondent</h2>
                <p className="article-p-margin"> Unlike an affiliate, a correspondent is a partner of the principal
                    license holder who receives legal instruction to effect cross border transactions. Correspondence is
                    not limited to individuals or licensed personnel; it is most often referenced in the context of
                    banking. Entrepreneurs in the digital payments sector will therefore need to find a correspondent
                    bank to conduct business in the United States. There are domestic correspondents and foreign
                    correspondents. If you are a European entity, your US correspondent would be a foreign
                    correspondent. To be a foreign correspondent, a contract between you and the principal license
                    holder in the US is required. This contract allows for transactions to be sent for settlement or
                    termination. In each case, a correspondent agreement is required; this agreement must be presented
                    to state regulators in the event of an audit. Just like an affiliate is not legally empowered to
                    touch funds, as a correspondent, you are not legally empowered to enact transactions from the US,
                    independent of the principal license holder. However, a correspondent can receive funds from a US
                    Money Service Business (MSB). Local correspondents are internally licensed entities who offer
                    similar services in jurisdictions where you are not licensed. In this case, a correspondent contract
                    does not invoke licensure rights on the contract but merely allows for a transaction to be settled.
                    It is pertinent to mention that when a transaction originates from a correspondent, that
                    correspondent has legal ownership of it, and neither the domestic nor the foreign correspondent can
                    canvas business in the other’s country. For example, a Puerto Rican bank, called an International
                    Financial Entity (IFE), therefore, will need to find a US correspondent bank if it wishes to acquire
                    US customers who are individuals rather than businesses. </p>


                <h2 style={{'marginTop': '20px'}}>Independent Sales Organization</h2>
                <p className="article-p-margin"> Independent Sales Organizations (ISOs) can also be used for money
                    transmission. An ISO Agreement serves several purposes. First, the ISO agreement is issued by the
                    Principal License Holder to the ISO which enables the ISO to sell or promote its products and
                    services. Basically, the Agreement is established by the Principal License Holder in order to market
                    their own products or services, however an ISO also acts as a correspondent. In its dealings with
                    the Money Service Business, the Money Service Business remains the legal owner, and the flow of
                    funds passes through the bank account of the Money Service Business, but the ISO can still solicit
                    or canvass for business in the Principal License Holder’s jurisdiction. The websites, apps, and
                    platforms that the ISO uses to sell the products remains property of the ISO, not the PLH. </p>
                <h2 style={{'marginTop': '20px'}}>Agent Program</h2>
                <p className="article-p-margin"> Agent programs allow entrepreneurs to acquire money transmitter
                    licenses from a corporate entity who is a Principal License Holder. In this kind of arrangement, the
                    start-up is a Money Transfer Operator (MTO) and functions as an authorized delegate. At launch, the
                    start-up can own its own brand and directly onboard clients in the interest of the Principal License
                    Holder. In this arrangement, the flow of funds travels through the bank of the Principal License
                    Holder, and the products or services that the start-up markets must be in line with the services the
                    Principal License Holder sells. Each agent working for the start-up must be conscious of this rule
                    for the sake of regulatory compliance. Usually statutory regulators monitor the nature of agency
                    relationships because there have been some careless PLH arrangements that have turned criminal.
                    Criminality occurs when a license is “rented” and unmonitored by the Principal License Holder. If
                    your start-up intends to carry out business that falls within the territory of the state of Texas,
                    for example, there are relevant cases which cracked down on PLH’s who rented their licenses and did
                    not properly monitor the usage. The <a
                        href='https://www.dob.texas.gov/public/uploads/files/Laws-Regulations/New-Actions/sm1038.pdf'>Supervisory
                        Memorandum (1038)</a>, from the Texas Department of
                    Banking (2014), is worth perusing, especially for companies using virtual currencies.

                    As an authorized delegate or agent, you will be required by the license holder to provide a brief
                    summary of your business. That summary will include a project plan, a projected revenue model for at
                    least one year, a mapped out funds flow chart including various payment paths (USD v. crypto paths
                    for example) and the jurisdiction of the business. Most PLHs will require that you indicate the
                    number of states you will carry out business operations in, as well as the time it will take to
                    kickstart the business. If your proposal is successful, a Principal License Holder will then allow
                    you to move on to the next stage of due diligence.
                </p>
                <h2 style={{'marginTop': '20px'}}>Banking Agent</h2>
                <p className="article-p-margin">Banks in the US are not required to adhere to Money Transmitter License
                    requirements by virtue of their Banking Charter. And, they can appoint subsidiaries who
                    automatically become free from the rigours of acquiring a Money Transmitter License. </p>


                <div className="flex site-block__wrapper column-wrap">
                    <div className='flex-column marginTop50'>
                        <h2>Own Your License</h2>
                        <p className="article-p-margin">Owning your MTL licenses outright is the ideal way to ensure your
                            ability to run a money service business. Generally, Fanbase can register your entity in the US or
                            get you registered as an MSB, at the federal level, without too much difficulty. However, as
                            mentioned, the method to acquire MTLs in each state, is no walk in the park. Generally, you must
                            consider the type of resources you have, the amount of time you have to process the material and
                            await a response, and the manpower it requires to compile all the documentation. While in the past,
                            you may have written a white paper as a way to organize your ideas, the Money Transmitter Licenses
                            will require a robust business plan, and many other documents which are quite complex. For most
                            entrepreneurs, the main issue confronting their choice concerns the issue of funding. If you are a
                            bootstrapped crypto startup, the best approach may be to register your company first in an agreeable
                            environment such as the EU, grow your business, build a clientele base, and garner financial
                            stability there before tackling the US market because the state licenses will each require you to
                            demonstrate a minimum net worth, a surety bond of $500,000 or $1,000,000 (depending on the state), a
                            compliance team, full time backend support, and a tech team.
                        </p>
                    </div>
                    <img src={ImageArticle02} className="privacy-warning-img-page rightImg-sml marginTop80"/>

                </div>
                <p className='marginTop10'>You will also be required to meet the
                    high standards of the US Bank Secrecy Act as it relates to your KYC and AML program. Other factors to
                    consider are the annual license, solicitor, and audit fees; documentation of criminal convictions in
                    the last 10 years; financial disclosures from yourself and your investors; and documentation
                    concerning your banking partnerships and limitations, to name but a few of the documents. After
                    compiling everything necessary for one state, the most frustrating part of all of it is that the
                    requirements vary from state to state, so you will need to rinse and repeat the step about 30 times.
                    The one bit of silver lining through all the bureaucracy is that a group of states do have a
                    Multi-State MSB Licensing agreement (called the MMLA) which allows you to submit one application for
                    26 states, at once. California, Illinois and Texas are currently part of that MMLA group, which gets
                    you some major metropolitan populations such as LA, San Francisco, Chicago, and Houston. The New
                    York Bit License, however, is a mind blowing $150,000 for just one state. Knowing that similar
                    licensure to the New York Bit License, is available for the totality of Europe, for about 1/10 the
                    capital investment, makes most entrepreneurs cringe at the cost-benefit analysis.</p>

                <h2 style={{'marginTop': '20px'}}>Where should I start?</h2>


                <p className='article-p-margin'>Generally, apart from meeting the requirements for running a Money
                    Service Business, Money Transmitters in the US also deal with a variety of supervisory bodies at the
                    state level, depending on which states the Money Service Business will transact. It is important
                    for Money Transmitters to institute measures of recordkeeping, file currency reports regularly, and
                    document suspicious activity with SARs. But, the greatest challenge is understanding each law that
                    is applicable to conducting business in each state. This is why Fanbase does not recommend
                    bootstrapping this part of your business (in any standard pro se or DIY approach), unless time is
                    not of the essence and you have 2 or 3 years to get acclimated to the application system. For more
                    information on these challenges, read our article titled <i>Acquiring Money Transmitter Licenses in
                        All
                        50 States</i>!? Or, contact us if you’d like to start the process. It is not an impossible task,
                    just an
                    uphill one, and if you want to do it, we are by far the best bang for your buck in acquiring your
                    Money Transmitter Licenses. </p>
                <p><i><b>Need help with MTLs? Contact <a
                    href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a> in
                    the US.</b></i></p>
            </div>

        </div>
    );
};

export default ArticleTips3;