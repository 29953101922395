import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/02.jpg'
import ImageArticleGraph from '../../../img/articles/02-dop.png'

const ArticleStartEu = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>STARTING AN E-RESIDENCY COMPANY IN THE EU

                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>JULY 15, 2020<br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com</a>
                </h3>
            </div>
            <div className="flex site-block__wrapper">
                <img src={ImageArticle} className="privacy-warning-img-page leftimg-small"/>
                <div className='flex-column'>
                    <p>The EU e-residency program is a system that allows digital entrepreneurs to start and manage EU-based
                        companies online. There are many affordances for e-residency entrepreneurs who live in
                        jurisdictions that are not friendly to their particular type of company, or to regular travelers
                        who have no particular country in which they predominantly reside. </p>
                        
                        <br/>
                        <p>When government officials,
                        consultants or attorneys speak of e-residency, they are generally referring to the Lithuanian system,
                        which is arguably the easiest and best system on the planet to register your entity if you are a
                        digital company or digital nomad. It is the opinion of many that this small country in Northern
                        Europe is the most forward-thinking of all EU member states, and really, most of the world.</p>
                        <br/>
                        <p> Lithuania, as one of the fastest growing digital nations, was the first to offer the e-residency program.
                        Completion of the Lithuanian e-registration qualifies the entrepreneur for a government issued digital
                        identity and legal status which gives global citizens easy access to Lithuania’s digital business
                        environment while e-registration allows them to work from any location on the planet.  </p> 
                       
                        
                </div>
            </div>
            <div className="site-block__wrapper">

                


               
                
                <div className="flex site-block__wrapper">
                    <div className="privacy_flex-column site-block__wrapper">
                        <p className='marginTop20'>
                        
                        <p> Lithuania is widely regarded as the most transparent regulatory system for entrepreneurs to operate and apply,
                        and Lithuania is home to companies like Bolt (formerly known as Taxify), Skype, and Transferwise.
                        The Lithuanian marketplace displays many service providers that can help register and support your new
                        business and provide solutions for remote business administration. At Fanbase, we support all of
                        your local e-registration needs also. </p>
                        
                        
                        <br/>
                        <p> To kick start the company you will first need a contact person. That person need not be a shareholder, board member, or employee
                        of your company and you do not need to grant the contact person any rights to act on behalf of
                        the company.  Second, you will need a virtual office (i.e a legal address and a contact person)
                        if you do not reside in Lithuania. Third, you will need an accountant that understands Lithuanian tax law, to handle your Lithuanian taxes. Fourth, you will need to locate a suitable bank to do business in Lithuania and within the EU. For some,
                        payment service solutions like Transferwise or N26 will be sufficient. For crypto companies, these will likely not suffice. Fanbase LLC provides all of these services and more. </p>
                        <br/>
                        
                            In most cases, getting a bank account is the most difficult step because e-residency status
                            cannot be used as leverage to open a personal or business bank account in Lithuania. Contact us via email
                            at <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need more information'>dex@fanbaseconsulting.com</a> for more information, or if we can be of assistance.
                            <b> Fanbase LLC</b> is the global leader in crypto and e-money registrations and is the preferred
                            service provider of the fanbase.io crowdfunding portal. </p>
            
                        <br/>
                        <p>If you would like to read more about
                            e-registering your company in Lithuania, we specifically discuss the cryptocurrency license
                            in our articles titled
                            <a href='/article-tips'> Tip #1: Register your Crypto Company in the EU</a> and
                            <a href='/article-starting-eu2'> Tip #2: The EU Crypto License Process</a>.
                        </p>
                        <p className='marginTop20'><i><b>For more information, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'>
                        dex@fanbaseconsulting.com</a> in the EU.</b></i></p>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default ArticleStartEu;