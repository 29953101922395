import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const AML = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Anti-Money Laundering
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>NEED HELP WITH AML/ KYC POLICIES?<br/>
                <p>If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question.'>
                    dex@fanbaseconsulting.com</a>.</p>
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>The Know Your Customer (KYC) process is required by governments to certify a customer’s personal information. Your KYC program ensures that your customers provide proper credentials before they are able to use your service. This is important to ensure that you are providing a safe, secure, and legitimate operation when doing business with your customers and to guard against criminal activity.
                <ol className="privacy_text-ol">
                    
                    </ol>
                    The Anti-Money Laundering (AML) process is also required by governments. This process regulates the illegal financing of businesses and safeguards against terrorism and money laundering. To ensure the proper usage of AML, you must create AML policies that follow AML rules and regulations according to the country you are operating. The point is to make sure you collect enough customer information to ensure proper verification and legitimate activities from the user.
                    Fanbase is able to assist with both measures as we will ensure your business follows the correct guidelines and has the proper procedures in place, so you do not fall victim to legal issues. Fanbase can also provide Anti-Money Laundering personnel who review digital asset or financial transactions and safeguard against threats of laundering. 

                </p>
                <ol className="privacy_text-ol">
                    
                </ol>
                <p>
                </p>
                <p>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'>
                dex@fanbaseconsulting.com</a> for help writing or implementing your AML or KYC policies and procedures.</p>
            </div>

        </div>
    );
};

export default AML;