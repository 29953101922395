import './CountryCards.scss';
import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";

import EE from '../../img/contries/ee.png'
import LT from '../../img/contries/lt.png'
import PR from '../../img/contries/pr.png'
import US from '../../img/contries/us.png'

const CountryCards = ({type, img}) => {

    const countriesData = [
        {country: 'United States', city: '', btnText: 'Funding Services', image: US},
        {country: 'Lithuania', city: '', btnText: 'Crypto Services', image: EE},
        {country: 'Puerto Rico', city: '', btnText: 'Banking Services', image: PR},
        {country: 'Lithuania', city: '', btnText: 'E-money Services', image: LT},
    ];

    const mailRedirect = (country, city, btn, type) => {
        let mail;
        if (type === 'country') {
            mail = `mailto:dex@fanbaseconsulting.com?subject=I am interested in discussing ${btn} and/or ${country}.`;
        } else {
            mail = `mailto:dex@fanbaseconsulting.com?subject=I am interested in discussing a different industry or jurisdiction.`;
        }
        window.location.href = mail;
    };



    return (
        <>
        <div className="home__global__mission" style={{"background": `url(${img}) `}}>
            <div className="home__global__card">

                {countriesData.map((countryObject, idx) => (
                    <div key={countryObject.country + idx} className='county_block'>
                        {/*<img src={countryObject.image} className="county_flaq"/>*/}
                        <div style={{"display": "flex"}}><h4>{countryObject.country}</h4>
                            <p>/{countryObject.city}</p></div>

                        <div
                            onClick={() => mailRedirect(countryObject.country, countryObject.city, countryObject.btnText, 'country')}
                            className={'county_btn' + (type === 'services' ? ' ghost' : '')}>
                            <p>{countryObject.btnText} </p>
                        </div>

                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default CountryCards;
