import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const CompanyRegistration = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Company Registrations
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>NEED TO REGISTER A COMPANY?<br/>
                    If so, contact <a
                    href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
               
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p> In most jurisdictions, corporate registrations are easy, however choosing the correct jurisdiction and setting up the corporation properly, the first time, is the key.  
                    Depending on the jurisdiction, some document preparation might be required, 
                    however new companies can be registered relatively quickly, in the same day or the same week, depending on several factors, like which country and state that you choose,
                    and whether or not you are dissolving or transferring an associated entity. If you are unsure about where to register your company, inquire with Fanbase.
                    <br/><br/>
                    If you wish, Fanbase can prepare the registration and associated operating agreement for your newly registered entity. 
                    As business consultants we will walk you through the best practice and best jurisdictions for your particular venture by
                    highlighting the advantages and disadvantages of each location.
                </p>
                <br/>
                <p>We are particularly experienced setting up EU, US, and Puerto Rican fintech and banking corporations as well as offshore broker-dealers in places like the British Virgin Islands.
                    
                </p><br/>
              
                <p><i><b>For more information, contact <a
                    href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question about registering a company.'> dex@fanbaseconsulting.com</a>.</b></i></p>
            </div>

        </div>
    );
};

export default CompanyRegistration;