import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Contact
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>JULY 15, 2020<br/>
                    Written by dex@fanbaseconsulting.com
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>We are the cool company based on Lithuania and USA</p>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
            <b>dex@fanbaseconsulting.com</b>
            <b>EU Office (HQ) </b>
            <p>Girulių g. 20</p>
            <p>Vilnius 12123, Lithuania.</p>
            <p>_____________</p>
            <b>US Office</b>
            <p>1811 N. Howard St.</p>
            <p>Philadelphia PA, 19122.</p>

            </div>

        </div>
    );
};

export default Contact;