import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const Accounting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Accounting
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>ARE YOU A START-UP LOOKING FOR AN ACCOUNTING FIRM?<br/>
                    If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
              

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p> In most cases, it makes sense for your start-up to outsource your accounting and tax needs to local professionals rather than hire an internal employee, at the start of your 
                    venture. Our accountants are inexpensive, locally sourced and experienced in various
                    fields like cryptocurrency, technology, entertainment, logistics, biotech, management and transportation. Generally you want to hire a US accountant to do your US taxes, 
                    if you are an American company, and an EU Accountant to do your European taxes if you are an EU, or Lithuanian company. 
                    If you are seated in another part of the world, contact Fanbase for your US or EU accounting services.

                    
                </p>
                <ol className="privacy_text-ol">
                </ol>
                <p>We can help you with financial analysis, financial forecasts, object or project-based revenue and cost accounting. To best serve you, 
                    let us know of your specific company activity, the number of employees, whether your company is subject to VAT, the average monthly sales and your total number of purchase invoices. 
                    As a rule, Fanbase does not handle your accounting unless we are also involved in several other aspects of your business.


                </p>
               
                
                <ol className="privacy_text-ol">
                </ol>
                <p><i><b>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a> with questions about tax law or accounting.</b></i></p>
            </div>

        </div>
    );
};

export default Accounting;
