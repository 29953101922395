import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/01.jpg'

const ArticleTips = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>TIP #1: REGISTER YOUR CRYPTO COMPANY IN THE EU
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>JULY 12, 2020<br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com</a>
                </h3>
            </div>
            <div className="flex site-block__wrapper">

                <img src={ImageArticle} className="privacy-warning-img-page leftimg"/>
                <div className='flex-column'>
                <p>A member state of the European Union, the small country of Lithuania, in Northern Europe,
                    is widely regarded as the premier destination for decentralized companies, exchanges,
                    wallets and tokens who operate their platforms using cryptocurrencies, digital assets or
                    digital ledger based tokens. The Lithuanian cryptocurrency license is generally the most
                    inexpensive to acquire, the Lithuanian regulatory bodies are quite pro-business, and the Lithuanian
                    Financial Intelligence Unit (FIU) is supportive and helpful. Most digital entrepreneurs agree
                    that the small country of Lithuania leverages its size and forward thinking attitudes to create a
                    welcoming environment that is 5-years ahead of jurisdictions like the United States.
                    <br/> <br/> The Lithuanian cryptocurrency license allows access to the European Union market and many other
                    jurisdictions in Asia and South America. However, as of March 10th, 2020, there are new regulations
                    for all cryptocurrency businesses registered in Lithuania. By virtue of the regulations, existing
                    license holders had until July 1st, 2020 to comply with the new laws which required existing
                    companies that were already e-registered to now host Point of Contact (POC) Board Members seated
                    in local offices. There have been other changes as well. For more information on these changes,
                    as well as additional regulations concerning license procurement, check out our article
                    titled <a href='/article-starting-eu2'> Tip #2: The EU Crypto License Process</a>.
                </p><br/> 
                
                </div>
            </div>
            <div className="site-block__wrapper">
            <p className='article-p-margin'> Obtaining a cryptocurrency license in Lithuania comes with some challenges,
                        particularly as a foreign entrepreneur or investor, however, as mentioned,
                        there are huge advantages for start-ups registering in Lithuania.
                        It is the opinion of Fanbase LLC, the global leader in crypto and e-money
                        corporate registrations, that Lithuania is the best location on the planet, in almost all cases,
                        for bootstrapped crypto start-ups. We have therefore compiled some basic recommendations for
                        entrepreneurs looking to register crypto businesses or raise money in the EU, as well as
                        existing companies seeking clarity, as there have been other regulatory changes
                        relevant to those companies also. Here is that list:</p></div>
            <div className="site-block__wrapper">

                <ol className="privacy_text-ol">
                    
                    <li>
                        You will be required to provide criminal record documentation.
                        Where that company cannot fulfil the requirement of proof of a
                        non-criminal record, the FIU will decline the application.
                        If you have a minor criminal offense, like Driving While Intoxicated, our
                        experience is that this is not an issue, although if it was very recent, it might be.
                        If you have committed financial crimes, or have been involved in financial misappropriation,
                        your application will be scrutinized.
                    </li>
                    <li>
                        You will find that United States and EU citizens can move to Lithuania with relative ease, whereas
                        applicants from other countries, such as Russia, may experience yearly quotas, and should
                        therefore
                        apply for residency on January 1st. The Lithuanian government claims that some types of jobs,
                        such as IT, do not have quotas, although some applicants have reported difficulties.
                        The first step would be to register all owners, and all members of the team that are moving to Lithuania as
                        e-residents at <a
                        href='https://e-resident.gov.ee/become-an-e-resident/'>e-resident.gov.ee/become-an-e-resident/ </a>.
                        You should do this as soon as possible because it can take about 5 weeks to
                        process and pick-up your ID in your own country. Alternatively, you can grant Fanbase a
                        Power of Attorney privilege to handle your incorporation in Lithuania.
                    </li>
                    <li>
                        There are regulatory requirements for your local team. It is recommended that your Local
                        Board Member have a spotless criminal record, and that your Anti-Money Laundering professional
                        have a law, finance, or business degree. It is not always required that the AML be located in
                        Lithuania, although the FIU may want to interview the individual in person. In some instances
                        for some companies, they may require the AML to be local. To avoid hiccups, hire locally.
                        Sometimes the Local Board Member and the AML can be the same person, although an application
                        structured as such can take longer and invite more scrutiny. Some companies hire Fanbase
                        personnel on a six month or one year contract, to ensure a smooth application process, and then
                        bring in their own replacements.
                    </li>
                    <li>
                        There are other service providers that handle compliance and staffing in the same way that
                        Fanbase does. In some instances they will assign you a part-time employee, as some of them sit
                        on the board of multiple companies. Be careful of this approach, because it can result in their
                        attention and focus being divided amongst many responsibilities, which may result in
                        unsatisfactory work. More importantly, the FIU will likely scrutinize your team, if this is
                        the case. Hence, when you make an application for a cryptocurrency license and the
                        FIU discovers the name of your contact person or compliance officer on multiple applications,
                        your application is likely to be flagged and queried. Any part-time employee Fanbase hires on
                        your behalf, works only for you as a Compliance or AML professional, even if they are part-time
                        employees. We work as a staffing agency, in some ways, so if you want to hire your Fanbase
                        assigned personnel at the end of a six month or one year contract, you may do so.
                    </li>
                    <li>
                        There are office requirements for your staff. The FIU, on occasion,
                        may visit an office to check on whether or not your employee is accessible
                        and seated in Lithuania. In your application your Lithuanian Board Member must provide
                        documentation proving Lithuanian residency. Often a utility bill is requested. Your Local
                        Board Member can be a resident. They do not have to be an Lithuanian citizen. Currently,
                        Fanbase provides a shared office space in Vilnius, for a fee, where we seat and mentor your
                        new Board Member hires. This is generally less expensive than hiring your own team or
                        renting an office space yourself.
                    </li>
                </ol>
                <p>Note that the financial regulatory bodies are always keen to monitor and ensure the smooth operations
                    of companies operating under the license, so it is important to have a proper management setup with
                    a knowledgeable Board Member and AML Officer, as well as a high level of transparency in your
                    dealings with your team as well as the FIU.  </p><br/> 
                    
                    <p>For more information about how Fanbase can help you build a healthy and lasting relationship
                    with the governing bodies, management, and anti-money laundering professionals, write us at
                    <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a>. We make
                    life easy. </p><br/> 
                <p><i><b>For more information, contact <a
                    href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a> in
                    Vilnius, Lithuania.</b></i></p>
            </div>

        </div>
    );
};

export default ArticleTips;