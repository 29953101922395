import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/tip6.jpg'
import ImageArticle01 from '../../../img/articles/tip4-1.jpg'


const ArticleTips6 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>TIP #6: STEPS TO INCORPORATE YOUR BANK IN PUERTO RICO
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>28th July, 2020<br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com</a>
                </h3>
            </div>
            <div className="flex site-block__wrapper">
                <img src={ImageArticle} className="leftimg"/>
                <p>If you are a banking entrepreneur, payment service provider, or cryptocurrency company seeking to
                    register an International Financial Entity (IFE) in Puerto Rico, you have a variety of
                    considerations to evaluate. For starters, you will need a banking license, and you should understand
                    that the banking license process is significantly more involved than the mere incorporation of a
                    company. In fact, you should expressly NOT register a company prior to registering your bank,
                    because registering a bank is an altogether different process. For new applicants, the Puerto Rican
                    Commissioner of Financial Institutions (OCIF) provides a menu of bank types to choose from, which
                    need to be clarified in your application, along with the services you will provide and the clients
                    you will serve. One interesting thing to note, for example, is that all IFEs must use the word
                    “International” or “Overseas” in the name of their entity and IFEs cannot serve US-based natural
                    persons, however they can serve US-based legal persons, like business entities. For example, your
                    bank can have Facebook as a client, and Facebook can have US individuals as their clients, but you
                    cannot take US natural persons as your direct clients in any depository way.
                </p>

            </div>
            <div className="privacy_flex-column site-block__wrapper margin-top-0">
                <p className='marginTop10'>To begin, a foreign entrepreneur must know which services she will provide to
                    her clients. In the application process, some services are easier than others to gain licensure, and
                    an entrepreneur should keep the initial application as focused as possible. There are reasons to
                    have well articulated plans that systematically outline the modus operandi of the entity; generally
                    the more loose ends you include, the more reasons the Commissioner can find to deny your
                    application, so it is best to know your core business and to describe it thoroughly. All business
                    plans submitted to the OCIF should follow the Federal Reserve Bank of New York (FRBNY) Business Plan
                    Guidelines. If you are unsure about writing the business plan yourself, or need expert help on a
                    variety of matters, Fanbase LLC handles all aspects of the application process, including the
                    business plan, at a flat-rate price. The OCIF requires that an attorney local to Puerto Rico submit
                    the documentation and handle the negotiations; and, Fanbase has a Puerto Rican attorney on staff so
                    we do not need to send you to another firm. Generally, it makes sense for banks to focus their
                    efforts on building the team and pitching investors, while professionals with proven formats prepare
                    the documentation. If interested, the first step is to contract Fanbase services. Next, Fanbase
                    prepares your preliminary license application and submits your initial check for $5000 to the OCIF,
                    notifying them of your intent. The outcome of the preliminary application will determine whether or
                    not you have permission to submit further documentation to operate the IFE. Later, you will be
                    required to provide a proof of funds document from your bank for $550,000; eventually $300,000 of
                    that will be placed as surety in a Puerto Rican bank account. Several months later, you will need a
                    detailed plan and evidence of the ability to raise the additional 2 to 3 million dollars.
                </p>


                <p className="article-p-margin">
                    Therefore, financially speaking, there are three key steps. For the first step you will need $5,000
                    and the preliminary application in order to receive the Permit to Organize. Then you will need
                    $550,000 in capitalization and some additional documentation to receive the Permit to Operate.
                    Finally, upon acceptance, you will need to raise an additional $2 to $3 million within 6 months with
                    the ability to petition for an extra to 6 months. Finally, after producing the last round of capital
                    you earn the permit to Commence Operations. Clients who reach the final stage, generally, do not
                    have difficulty raising the 2 to 3 million dollars because the initial process takes about 8 months,
                    and to have received the go-ahead from the OCIF is often proof positive of competence, and a sound
                    business plan, to investors. Required documents include:

                </p>
                <ul className="privacy_text-ol">
                    <li>
                        Certified copies of application documents filed at the Department of State of Puerto Rico.

                    </li>
                    <li>
                        Comprehensive financial reports

                    </li>
                    <li>
                        Proof of noncriminal records of shareholders, directors and officers.

                    </li>
                    <li>
                        Certificate from the Department of State of Puerto Rico stating that the organizational
                        documents and Permit to Organize have been filed.

                    </li>
                    <li>
                        Proof of subscription of the authorized capital.
                    </li>
                    <li>
                        Evidence indicating compliance related to maintaining unencumbered assets.

                    </li>
                    <li>Certified by-laws which demonstrate adoption by the Board of Directors
                    </li>
                    <li>A Compliance/AML team and an IT team.
                    </li>
                    <li>Proof of a Puerto Rican office and residency in Puerto Rico for four office workers.
                    </li>
                    <li>A certified certificate of corporate resolution demonstrating compliance with Act 273 and
                        Regulation 5653.
                    </li>
                </ul>
                <p className="article-p-margin">
                    Additional legwork is also necessary, and the importance of a correspondent bank is fundamental to
                    the operating license. While the licenses are referred to <i>as permits to organise, permits to
                    operate
                </i> and <i>permits to commence operations</i>, all documents provided during the three phases of the
                    application process will serve to demonstrate the strength and integrity of the financial entity.
                    Therefore, a permit to organise signals acceptance by the government to kickstart IFE operations but
                    it does not grant a license to conduct business. The Commissioner of Financial Institutions may
                    grant, conditionally grant or even deny an application for the license at various stages of the
                    process. Once permission to organize has been given, other necessities like building compliance
                    infrastructures and hiring a team can follow. To earn a <i>permit to operate</i> additional
                    requirements
                    must be in place. As you progress through each stage, you will file your IFE licenses at the Puerto
                    Rican Department of State within 10 days from the date of issuance. Your IFE should request tax
                    exemption from the Department of Economic Development and Commerce (DDEC) in Puerto Rico.
                </p>
                <p className="article-p-margin">
                    Puerto Rico previously required five employees as the minimum but with changes made to Puerto Rico’s
                    Act 20 and 22 since July 2017, that requirement was abdicated with no employee requirement. This
                    gave way for more flexibility in business operations. You can be based in the US and operate the
                    venture through a Puerto Rico company, and get the tax deferral at 4%. In that case, you are
                    required to take a fair market salary that’s taxable and report the same on Form W-2 (Puerto Rico’s
                    electronic filing specification).

                </p>
                <p className="article-p-margin">
                    Also, you can conveniently run a business as a sole proprietor and qualify for the Act 20 4% tax
                    rate so long the conduct of the business is done in Puerto Rico. It is referred to as Puerto Rico
                    sourced income. With Puerto Rico currently seeking to be a sizable hub for regional and
                    international business ventures, the necessity for the amendment of the law is further brought to
                    the fore. Apart from no imposition of federal taxes and the income tax rate being fixed, there is
                    100% tax exemption on dividends, 100% exemption on property tax for the first 5 years after which it
                    becomes 90%, also, on municipal taxes, there is 60% exemption. Depending on the location in Puerto
                    Rico, the business might get 90% on municipal taxes if set up within an ‘industrial development
                    zone’. These rates can be renewed for another 10 years subject to certain terms and conditions. The
                    IFE may any person incorporated under Puerto Rican laws, US laws or the laws of other territory. The
                    start point for such a person is to pay a non refundable fee of $5,000 at the point of filing the
                    application for the permit. After which he is to essentially comply with the requirements as
                    stipulated under the Bank Secrecy Act. Exemptions may be granted to an IFE, for example exemption
                    from CTR filing requirements. Note that exemptions are of 2 types (phase 1 and phase 2). Exemption
                    from CTR filing requirements will apply in Puerto Rico largely based on the fact that by virtue of
                    the Bank Secrecy Act, any entity exercising governmental authority within the U.S. (U.S. includes
                    District of Columbia, Territories, and Indian tribal lands) falls under the category of an exempt
                    person.
                </p>
                <p className="article-p-margin">
                    Worthy of note is the fact that the authorized capital stock shall not be less than $5,000,000 of
                    which $250,000 must have been fully paid at the time the license is issued. The Act also provides a
                    list of prohibited transactions that the IFE cannot engage in. A potential investor who fulfills the
                    requirements for running an IFE should note the following:
                </p>

                <ol className="privacy_text-ol">
                    <li>
                        The perks of Act 20 is subject to running the business from within Puerto Rico. Where the income
                        is sourced from the US, it will be taxed and will not qualify for the 4% tax rate.
                    </li>
                    <li>
                        Such income will also be taxed by the US even if you run the business via a Puerto Rican company
                        that is a legatee of Act 20.
                    </li>
                    <li>
                        Not all business activities qualify for Act 20. (Wholesale distribution will pass off under the
                        Act)
                    </li>
                    <li>
                        Have a correspondent banking partner.
                    </li>
                    <li>
                        The 100% exemption on dividends applies only to bona fide residents of the Island
                    </li>
                    <li>
                        Where dividends are made to a Puerto Rican resident from a Puerto Rico Act 20 company, they are
                        tax free.
                    </li>

                </ol>
                <div className="flex site-block__wrapper column-wrap">
                    <div className='flex-column'>
                        <p className="marginTop10">Opening an offshore corporation is generally intensive, hence there is the need for the investor to count the cost to wit; supply financial projections, work on his marketing and management plan while taking advantage of the numerous opportunities in the Puerto Rican business environment especially the perks of a free tax zone to wit protection of tax grants under the IFE Act, which secures the benefits for a period of 15 years and may further be renewed.

                        </p>
                        <p className='marginTop20'><i><b>Need help with Registering a Bank in the US or PR? Contact <a
                            href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a>.</b></i></p>
                    </div>
                    {/*<img src={ImageArticle01} className="rightImg-small "/>*/}
                </div>
            </div>
        </div>
    );
};

export default ArticleTips6;