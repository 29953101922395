import './AboutUs.scss';
import React, {useEffect, useState} from 'react';
import LinkedinOutlined from "@ant-design/icons/lib/icons/LinkedinOutlined";
import {Link} from "react-router-dom";
import CountryCards from "../../Components/CountryCards/CountryCards";

const AboutUs = () => {
    const [countriesNumber, setCountriesNumber] = useState(0);
    const [clientsNumber, setClientsNumber] = useState(0);
    const [officesNumber, setOfficesNumber] = useState(0);
    const [startCalculate, setStartCalculate] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        const statisticBlock = document.querySelector('.about-us__statistic__content');

        if (statisticBlock.getBoundingClientRect().top <= 750) {
            setStartCalculate(true)
        } else {
            window.addEventListener('scroll', () => {
                if (statisticBlock.getBoundingClientRect().top <= 750) {
                    setStartCalculate(true)
                }
            })
        }
    }, []);

    useEffect(() => {
        if (startCalculate) {
            calculateValue(20, setCountriesNumber, 1600);
            calculateValue(200, setClientsNumber, 1600);
            calculateValue(3, setOfficesNumber, 1600);
        }
    }, [startCalculate]);


    const calculateValue = (limit, setValue, delay) => {
        for (let i = 0; i <= limit; i++) {
            setTimeout(() => {
                setValue(i)
            }, delay)
        }
    }

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Team Mantra: <p> Efficiency and Value</p></h1>
            </div>
            <div className="about-us__slogans">
                <div className="about-us__slogans__item">
                    <h3>Efficiency Markers</h3>
                    <p> It is very likely that we have already researched and prepared any application you need submitted.
                        Don't reinvent the wheel when you can use our service and save yourself months of headaches. 
                        We provide straightforward timelines and efficiency markers which track your progress in attaining licenses.
                    </p>
                </div>
                <div className="about-us__slogans__item">
                    <h3>Value Added</h3>
                    <p> It is very likely that we are less expensive than any other service. And, we'd love to help launch your bank, fintech, crypto technology,
                        or media company. Don't pay an hourly rate when you can hire Fanbase for a flat rate.
                        We add an incredible amount of experience and value to your company because we eliminate the stress of registering and licensing your entity.
                    </p>
                </div>
            </div>
            <div className="about-us__statistic__wrapper">
                <div className="site-block__wrapper about-us__statistic">
                    <div className="about-us__statistic__title">
                        <span>Let us be your team</span>
                        <h2>Fanbase Consulting</h2>
                    </div>
                    <div className="about-us__statistic__content">
                        <div className="about-us__statistic__content__description">
                            <p> We have offices, banking options, and services in a variety of jurisdictions as well as contacts to local hiring. When you need to build out your new location, compliance team, 
                                or management board, use Fanbase!
                            </p>
                        </div>
                        <div className="about-us__statistic__content__numbers">
                            <div className="about-us__statistic__content__numbers__item">
                                <h3>{countriesNumber} +</h3>
                                <p>Banking Options</p>
                            </div>
                            <div className="about-us__statistic__content__numbers__item">
                                <h3>{clientsNumber} +</h3>
                                <p>Types of documents</p>
                            </div>
                            <div className="about-us__statistic__content__numbers__item">
                                <h3>{officesNumber} +</h3>
                                <p>Locations</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-block__wrapper about-us__members">
                <div className="about-us__members__title">
                    <span>Leadership</span>
                    <h2 className='mainTitle'>Fanbase Consulting</h2>
                </div>
                <div className="about-us__members__cards">
                    <div className="about-us__members__cards__item">
                        <span>EU Compliance</span>
                        <h3>Giedrė Gurskytė</h3>
                        <a href='https://www.linkedin.com/in/giedre-gurskyte-cams/'><LinkedinOutlined/></a>
                    </div>
                    <div className="about-us__members__cards__item">
                        <span>Global Consultant</span>
                        <h3>Ronald Mears</h3>
                        <a href='https://www.linkedin.com/in/ronaldmears/'><LinkedinOutlined/></a>
                    </div>
                    <div className="about-us__members__cards__item">
                        <span>Technology Consultant</span>
                        <h3>Evgeniy Gromov</h3>
                        <a href='https://www.linkedin.com/in/evgeniy-gromov-50bba559/'><LinkedinOutlined/></a>
                    </div>
                </div>
            </div>
            <div className="site-block__wrapper about-us__members__description">
                <p className='about-us__members__description__item'>
                    The Fanbase team has diverse expertise in law, financial services, higher education, technology, media, commerce and business 
                    on four continents which helps us navigate the complexities of international business and strategy.
                    
                    
                </p>
                <div className='about-us__members__description__item'>
                    <p>Fanbase is located in Vilnius, Lithuania, which has some of the most forward thinking laws on the planet for fintech, finance and technology companies.
                      Fanbase also has a network of partners across the globe, and an office in the United States.
                    </p>
                    <br/>
                    <p>Our core competency is our ability to get your company structured and legal at a fraction of the cost of a traditional law firm or consultancy.
                    </p>
                    <br/>
                    <p>
                    </p>
                </div>
            </div>
            
        </div>
    );
};

export default AboutUs;