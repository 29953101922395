import searchJson from './search'


const searchLinkTool = async (value, setSearchResult) => {
    const searchData = searchJson.searchJson;
    let coincidence = [];
    await searchData.map((item) => {
        let dataContent = item.content;
        if (dataContent.includes(value)) {
            coincidence.push({link: item.link, name: item.name});
        }
    });
    setSearchResult(coincidence);
    return coincidence;
};

export default searchLinkTool;
