import '../AboutUs/AboutUs.scss';
import './../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
// import Warning from '../../img/warning.png'

const PrivacyBottom = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='about-us'>
                {/*<div className="site-block__wrapper about-us__title">*/}
                {/*    <h1 className='mainTitle'>Privacy Policy*/}
                {/*    </h1>*/}
                {/*</div>*/}
                {/*<div className="privacy_flex-column site-block__wrapper">*/}
                {/*    <h3>NEED HELP WRITING YOUR TERMS OF SERVICE?<br/>*/}
                {/*        <p>If so, contact <a*/}
                {/*            href='mailto:serene@codipity.com?subject=Hello, I need GDPR help.'> Serene@Codipity.com</a>.*/}
                {/*        </p>*/}
                {/*    </h3>*/}
                {/*</div>*/}
                <div className="privacy_flex-column site-block__wrapper privacy-block">
                    <h4><b>Privacy Policy</b></h4>
                    <h4><b>
                        Effective date: December 20, 2020</b></h4>
                </div>
                <div className="privacy_flex-column site-block__wrapper privacy-block">


                    <p>Fanbase Network UAB ("Fanbase" or "us", "we", or "our") operates the www.fanbaseconsulting.com website from the European Union. This page informs you of
                        our policies regarding the collection, use, and disclosure of personal data when you use our Service and the
                        choices you have associated with that data. We use your data to provide and improve the Service. By using the
                        Service, you agree to the collection and use of information in accordance with this policy. Unless
                        otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same
                        meanings as in our Terms and Conditions. </p>
                    <br/>
                    <h4><b>Information Collection And Use </b></h4><br/>
                    <p>
                        We collect several different types of information for various purposes to provide and improve
                        our Service to you. </p>
                    <br/>
                    <h4><b>Types of Data Collected</b></h4> <br/>
                    <p>
                        <i> <b>Personal Data</b></i> <br/>
                        While using our Service, we may ask you to provide us with certain personally identifiable
                        information
                        that can be used to contact or identify you ("Personal Data"). Our Know-Your-Customer (“KYC”),
                        Know-Your-Business
                        (“KYB”), and Anti-Money Laundering (“AML”), services require that third party verifiers must
                        have access to your Personal Data. All KYC information is being submitted to Fanbase and
                        verified
                        through third party providers.<br/>
                        Personally identifiable information may include, but is not limited to:<br/>
                        • Email address<br/>
                        • First name and last name<br/>
                        • Phone number<br/>
                        • Address, State, Province, ZIP/Postal code, City<br/>
                        • Cookies and Usage Data</p>
                    <p style={{"marginTop": "20px"}}><i><b>Usage Data</b></i> <br/>
                        We may also collect information that your browser sends whenever you visit our Service or
                        when you access the Service by or through a mobile device ("Usage Data"). This Usage Data may
                        include information such as your computer's Internet Protocol address (e.g. IP address),
                        browser type, browser version, the pages of our Service that you visit, the time and date of
                        your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                        When you access the Service by or through a mobile device, this Usage Data may include
                        information such as the type of mobile device you use, your mobile device unique ID,
                        the IP address of your mobile device, your mobile operating system, the type of mobile
                        Internet browser you use, unique device identifiers and other diagnostic data. </p>
                    <h4><b>Tracking & Cookies Data</b></h4><br/>
                    <p>
                        We use cookies and similar tracking technologies to track the activity on our Service and
                        hold certain information. Cookies are files with small amount of data which may include an
                        anonymous unique identifier. Cookies are sent to your browser from a website and stored on your
                        device. Tracking technologies also used are beacons, tags, and scripts to collect and track
                        information and to improve and analyze our Service.</p>
                        <br/>
                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        However, if you do not accept cookies, you may not be able to use some portions of
                        our Service. Examples of Cookies we use:<br/>
                        • Session Cookies. We use Session Cookies to operate our Service.<br/>
                        • Preference Cookies. We use Preference Cookies to remember your preferences and various
                        settings.<br/>
                        • Security Cookies. We use Security Cookies for security purposes.</p>
                    <h4><b>Use of Data </b></h4><br/>
                    <p>
                        Fanbase uses the collected data for various purposes:<br/>
                        • To provide and maintain the Service<br/>
                        • To notify you about changes to our Service<br/>
                        • To allow you to participate in interactive features of our Service when you choose to do
                        so<br/>
                        • To provide customer care and support<br/>
                        • To provide analysis or valuable information so that we can improve the Service<br/>
                        • To monitor the usage of the Service<br/>
                        • To detect, prevent and address technical issues</p>
                    <h4><b>Transfer Of Data</b></h4><br/>
                    <p>
                    Your information, including Personal Data, may be transferred outside of your state,
                    province, country or other governmental jurisdiction where the data protection laws
                    may differ from those in your jurisdiction. At all times, we adhere to General Data
                    Protection Regulations (EU) 2016/679. If you are located outside the European Union
                    and choose to provide information to us, please note that we may transfer the data,
                    including Personal Data, to the United States and process it there. Your consent to this
                    Privacy Policy followed by your submission of such information represents your
                    agreement to that transfer. Fanbase will take all steps reasonably necessary to ensure
                    that your data is treated securely and in accordance with this Privacy Policy and GDPR;
                    no transfer of your Personal Data will take place to any organization or country where 
                    adequate controls are not in place to secure your data and personal information. </p>
                    <h4><b>Disclosure Of Data</b></h4> <br/>
                    <i><b>Legal Requirements</b> </i> <br/>
                    <p>
                        Fanbase may disclose your Personal Data in the good faith belief that such action is necessary
                        to:<br/>
                        • Comply with a legal obligation<br/>
                        • Protect and defend the rights or property of Fanbase<br/>
                        • Prevent or investigate possible wrongdoing in connection with the Service<br/>
                        • Protect the personal safety of users of the Service or the public<br/>
                        • Protect against legal liability</p>
                    <h4><b>Security Of Data</b></h4><br/>
                    <p>
                        The security of your data is important to us, but remember that no method of transmission over
                        the Internet, or method of electronic storage is 100% secure. While we strive to use
                        commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute
                        security. We may employ third party companies and individuals ("Service Providers"), to provide
                        the Service on our behalf, to perform Service-related services or to assist us in analyzing our
                        Services. These third parties have access to your Personal Data only to perform these tasks on
                        our behalf and are obligated not to disclose or use it for any other purpose. We may use
                        third-party Service Providers to monitor and analyze the use of our Service.</p>
                    <h4><b>Google Analytics</b></h4><br/>
                    <p>
                        Google Analytics is a web analytics service offered by Google that tracks and reports website
                        traffic. Google uses the data collected to track and monitor the use of our Service. This data
                        is shared with other Google services. Google may use the collected data to contextualize and
                        personalize the ads of its own advertising network. For more information on the privacy
                        practices of Google, please visit the Google Privacy & Terms web page:
                        https://policies.google.com/privacy?hl=en</p>
                    <h4><b>Links To Other Sites</b></h4><br/>
                    <p>
                        Our Service may contain links to other sites that are not operated by us. If you click on a
                        third party link, you will be directed to that third party's site. We strongly advise you to
                        review the Privacy Policy of every site you visit. We have no control over and assume no
                        responsibility for the content, privacy policies or practices of any third party sites or
                        services.</p>

                    <h4><b>Children's Privacy</b></h4><br/>
                    <p>
                        Our Service does not address anyone under the age of 18
                        ("Children"). We do not knowingly collect personally identifiable information from anyone under
                        the age of 18. If you are a parent or guardian and you are aware that your child provided us
                        with Personal Data, please contact us. If we become aware that we have collected Personal Data
                        from children without verification of parental consent, we take steps to remove that information
                        from our servers.
                    </p>

                    <h4><b>Changes To This Privacy Policy</b></h4> <br/>
                    <p>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                        the new Privacy Policy on
                        this page. We will let you know via email and/or a prominent notice on our Service, prior to the
                        change becoming
                        effective and update the "effective date" at the top of this Privacy Policy. You are advised to
                        review this Privacy
                        Policy periodically for any changes. Changes to this Privacy Policy are effective when they are
                        posted on this page. </p>

                    <h4><b>Contact Us <br/></b></h4><br/>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us by email at
                        dex@fanbaseconsulting.com.</p><br/>
                    <br/>
                </div>
            </div>

        </>


    );
};

export default PrivacyBottom;
