import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/partners.jpg'
import {Link} from "react-router-dom";
import ImageArticle from "../../img/articles/tip4.jpg";

const Franchise = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Partnerships
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>
                    ARE YOU AN ATTORNEY INTERESTED IN FINTECH?
                </h3>
                <h3>
                If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <div className="flex site-block__wrapper">
                    <img src={Warning} className="franchise_img"/>
                    <p> Fanbase employees and partners enjoy best in class support. In certain jurisdictions and for certain licenses, our clients require the work of attorneys, not business consultants.
                        Therefore, Fanbase generally seeks partnerships with licensed attorneys, or local accountants in pro-business jurisdictions. 
                        <ol className="privacy_text-ol">
                        </ol>
                        
                        Currently China, Hong Kong, and Singapore are on our expansion list, and we are adding representatives in additional jurisdictions. 
                        If you are located elsewhere and can contribute to the breadth and depth of Fanbase knowledge and experience, feel free to reach out.
                        In order to keep costs low for our clients, Fanbase prepares and shares our documents within the partnership, and when required by law, presents our clients' completed applications to licensed local attorneys for review and final submission. 
                        If you are an accountant or attorney, please contact us at <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I am looking to partner...'> dex@fanbaseconsulting.com</a> to discuss a potential partnership. In some jurisdictions, we are hiring full-time help.
                        
                        
                        
                        
                         
                    </p>
                    
                </div>

            </div>
            {/*<div className="privacy_flex-column site-block__wrapper">*/}
            {/*    <p>Codipity Partners enjoy best in class support and document preparation. For some licenses, in some jurisdictions, our clients require the work of attorneys, not business consultants.*/}
            {/*       Therefore, Codipity generally seeks partnerships with licensed attorneys or local accountants in a variety of jurisdictions. Currently China, Hong Kong, and Singapore are on our expansion list, but we are not averse to additional jurisdictions if you are located elsewhere.*/}
            {/*       In order to keep costs low for our clients, Codipity generally prepares documents or applications like paralegals, and presents the completed application to a licensed attorney for review and submission.*/}
            {/*       If you are a licensed attorney or accountant, please contact us to discuss a potential partnership. In some cases, we are hiring full-time, local representatives.*/}
            {/*    </p>*/}
            {/*   */}
            {/*</div>*/}

        </div>
    );
};

export default Franchise;
