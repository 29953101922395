import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/tip4.jpg'
import ImageArticle01 from '../../../img/articles/tip4-1.jpg'
import linksList from "./linkslist";


const ArticleTips4 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>TIP 4: ACQUIRING MONEY TRANSMITTER LICENSES IN ALL 50 STATES
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>JULY 17, 2020<br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com</a>
                </h3>
            </div>
            <div className="flex site-block__wrapper">
                <img src={ImageArticle} className="leftimg"/>
                <p>For Money Service Businesses, acquiring Money Transmitter Licenses
                    (MTLs) in the United States is a difficult process. The degree of difficulty, of course, varies from
                    state to state, and the extent of an entrepreneur's nightmare depends (1) on the specific state
                    where she is seeking the license and (2) the type of business she intends to conduct. While the
                    Financial Crimes Enforcement Network (FinCEN) regulates the activities of MSBs at the federal level,
                    each state, save the state of Montana, has local statutes that regulate the operations of money
                    transmitters. These states require the registration of anyone seeking money transmitter status. Even
                    though money transmitters in the state of Montana aren’t required to be licensed with the division
                    of Banking and Financial Institutions like most states require, in order to carry out money transfer
                    services MSBs must still register as a business with the Montana Secretary of State. There are,
                    however, common threads linking the registration processes and expectations of each state such as
                    compulsory fees for license application and renewals. But, because there is no general license that
                    covers the operation of money transmission services in the US, an applicant must obtain a license in
                    each state she intends to carry out business. The challenge of this is that she must familiarize
                    herself with the rules that apply in each of the 50 states and 5 territories, since the rules that
                    apply in state ‘A’ are likely different from what would apply in state ‘B’.
                    As a consequence, a
                    license seeker has to deal with a diverse range of regulations if she wants to operate in each
                    state.
                </p>

            </div>
            <div className="privacy_flex-column site-block__wrapper margin-top-0">
                <p className='marginTop10'> Moreso, the general license regulatory requirements are susceptible to change or amendments.
                    While Fanbase provides a flat-rate service to lead entrepreneurs through the MTL storm, below are
                    the links to help pro se (DIY) applicants conduct the research and submit the required documents to
                    55 different US jurisdictions themselves:
                </p>

                <ol className="privacy_text-ol">
                    {linksList.map((item) =>
                        <li>
                            <div className="flex-column">
                                <a href={item.stateNameLink}>{item.stateName}</a>
                                <a href={item.transferNameLink}>{item.transferName}</a></div>
                        </li>
                    )}
                </ol>

                <p className="article-p-margin">
                    Applicants begin by filling out the Nationwide Multistate Licensing System (NMLS) Forms (1) for
                    Individuals and (2) for Companies, which are the documents that sole proprietors and companies use
                    for maintaining non-depository, financial service licenses for state agencies associated with MTLs.
                    An applicant is also required to list the beneficial owners, and to list the managers. Last, an
                    applicant must submit the NMLS Branch Form used by companies to apply for and maintain licenses for
                    each non-depository, financial services branch location, if applicable. As a legal requirement,
                    applicants also must hold insurance. Various state laws require licensees to obtain surety bonds as
                    a condition of licensure because when a licensee fails to comply with the requirements, a regulator
                    files claims against the bond to cover the penalties.

                </p>
                <p className="article-p-margin">
                    Financial regulators are stalwarts of the Money Transmitters Regulators Association (MTRA), an
                    organization that is responsible for the implementation of the laws in all 55 jurisdictions. The
                    NMLS, which was instituted by the Conference of State Bank Supervisors (CSBS) and the American
                    Association of Residential Mortgage Regulators (AARMR) in 2008, aims to improve coordination,
                    efficiency and information sharing among regulators and to enhance consumer protection.
                    Unfortunately, compared to processes in most parts of the world, the US system is considered to be
                    ineffective, if not broken, as a result of the cumbersome processes involved with obtaining a
                    license and the lack of uniformity across the states. The difficulties are further exacerbated
                    because license seekers are rarely confident about what is required. Recently, an NMLS Policy
                    Guidebook was released as a resource material for applicants, licensees, and regulatory agencies. To
                    summarize, the requirements handled through common processes include (1) Annual Renewals; (2)
                    Electronic Surety Bonds; (3) Financial Statements; (4) Advanced Change Notices; (5) Policies; (6)
                    Professional Standards which include Criminal background checks, Completing pre-licensure education
                    requirements, Prometric tests, and Credit Report Authorizations; (7) Mortgage Call Reports; (8)
                    Money Services Businesses Call Reports; (9) Temporary Authorities to Operate; and (10) Uniform
                    Authorized Agent Delegate Reports. However, with the publication of the guidebook, it is hoped that
                    applicants will have a proper understanding of the policies that apply when using the NMLS.
                </p>
                <div className="flex site-block__wrapper column-wrap">
                    <div className='flex-column'>
                    <p className="marginTop10">Generally, what makes the application process cumbersome is the ripple
                        effect across states, industries, and continents because the global community generally looks to the
                        United States for clarity on economic and financial issues, as the United States is the world’s
                        largest economy. When the US falters, or fails to correct its inefficiencies, the world suffers
                        along with the US taxpayers. In some instances however, pro-business foreign governments can fill
                        the leadership vacuum. In order to provide clarity and to bridge the knowledge gap, <b>Fanbase
                            LLC</b>
                        provides MSB and MTL services to US and foreign entities by evaluating money transmission
                        methodologies vis-a-vis the relevant statutory requirements. Fanbase undertakes the extensive
                        research, and details the relevant information as to licensing obligations in all 55 jurisdictions
                        so that our clients don’t have to. For more information on the options available to anyone seeking a
                        money transmitter license in the US, kindly refer to our article titled <a href='/article-tip-3'>Tip
                            3: Understanding US
                            Money Transmitter Licenses</a>.
                    </p>
                    <p className='marginTop20'><i><b>Need help with MTLs? Contact <a
                        href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a> in
                        the US.</b></i></p>
                    </div>
                    <img src={ImageArticle01} className="rightImg-small "/>
                </div>
            </div>
        </div>
    );
};

export default ArticleTips4;