import Article01 from "../../img/articles/01.jpg";
import Article02 from "../../img/articles/02.jpg";
import Article03 from "../../img/articles/eu2.jpg";
import Article04 from "../../img/articles/tip3.jpg";
import Article05 from "../../img/articles/tip4.jpg";
import Article5 from "../../img/articles/tip5.jpg";
import Article06 from "../../img/articles/tip6.jpg";
import Article07 from "../../img/articles/tip7.jpg";

const preview = [
    {
        title: "STARTING AN E-RESIDENCY COMPANY IN THE EU",
        image: Article02,
        description: "The EU e-residency program is a system that allows digital entrepreneurs to start and manage EU-based companies online. There are many affordances for e-residency entrepreneurs who live in jurisdictions that are not friendly to their particular type of company, or to regular travelers who have no particular country in which they predominantly reside.",
        link: 'article-starting-eu',
        date: 'JULY 12, 2020',
        author: 'by dex@fanbaseconsulting.com'

    },
    {
        title: "TIP #1: REGISTER YOUR CRYPTO COMPANY IN THE EU",
        image: Article01,
        description: "A member state of the European Union, the small country of Lithuania, in Northern Europe, is widely regarded as the premier destination for decentralized companies, exchanges, wallets and tokens who operate their platforms using cryptocurrencies, digital assets or digital ledger based tokens.",
        link: 'article-tips',
        date: 'JULY 12, 2020',
        author: 'by dex@fanbaseconsulting.com'
    },
    {
        title: "TIP #2: THE EU CRYPTO LICENSE PROCESS",
        image: Article03,
        description: "The process for getting a cryptocurrency license in the European Union is easier than anywhere else in the world. If you are an entrepreneur, confronted stringent or bureaucratic procedures associated with obtaining such licenses in your own country and not realized that in some parts of the world, licensure is much easier.",
        link: 'article-starting-eu2',
        date: 'JULY 15, 2020',
        author: 'by dex@fanbaseconsulting.com'
    },
    {
        title: "TIP #3: UNDERSTANDING US MONEY TRANSMITTER LICENSES ",
        image: Article04,
        description: "In the United States, a money transmitter is a business that enables access to payment instruments, a financial entity that creates and manages money, or a currency transfer service. Under federal law, a money transmitter falls under the purview of a US Money Service Business (MSB). For MSBs, a federal license is issued by the Financial Crimes Enforcement Network (FinCEN), of the United States Department of Treasury. ",
        link: 'article-tip-3',
        date: 'JULY 16, 2020',
        author: 'by dex@fanbaseconsulting.com'
    }, {
        title: "TIP #4: ACQUIRING MONEY TRANSMITTER LICENSES IN ALL 50 STATES",
        image: Article05,
        description: "For Money Service Businesses, acquiring Money Transmitter Licenses\n" +
            "                    (MTLs) in the United States is a difficult process. The degree of difficulty, of course, varies from\n" +
            "                    state to state, and the extent of an entrepreneur's nightmare depends (1) on the specific state\n" +
            "                    where she is seeking the license and (2) the type of business she intends to" +
            " conduct... ",
        link: 'article-tip-4',
        date: 'JULY 17, 2020',
        author: 'by dex@fanbaseconsulting.com'
    },

    {
        title: "TIP #5: INCORPORATE YOUR BANK IN PUERTO RICO",
        image: Article5,
        description: "Puerto Rico is a carribean island territory bordered by the US Virgin Islands.  " +
            "Since 2012, it has garnered the serious attention of banks and financial service entities," +
            " like US broker-dealers, as an attractive regulatory environment free from US federal taxation. " +
            "While financial institutions in Puerto Rico are multiplying rapidly, they are still strictly regulated...",
        link: 'article-tip-5',
        date: 'JULY 28, 2020',
        author: 'by dex@fanbaseconsulting.com'
    },
    {
        title: "TIP #6: STEPS TO INCORPORATE YOUR BANK IN PUERTO RICO",
        image: Article06,
        description: "If you are a banking entrepreneur, payment service provider, or cryptocurrency company seeking to " +
            "register an International Financial Entity (IFE) in Puerto Rico, you have a variety of considerations to evaluate." +
            " For starters, you will need a banking license, and you should understand that the banking license process " +
            "is significantly more involved than the mere incorporation of a company... ",
        link: 'article-tip-6',
        date: 'JULY 28, 2020',
        author: 'by dex@fanbaseconsulting.com'
    },
    {
        title: "THE FUTURE: BLOCKCHAIN, FINTECH & THE MUSIC INDUSTRY",
        image: Article07,
        description: "Financial and blockchain technologies are changing the world in dynamic ways. Those changes are " +
            "fast- paced and cut across virtually every sector, revolutionizing finance, and making it possible for " +
            "people to access money services who previously only had access to local currencies and tangible forms of trade... ",
        link: 'article-tip-7',
        date: 'AUGUST 20, 2020',
        author: 'by dex@fanbaseconsulting.com'
    },


];


export default preview;