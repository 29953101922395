import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Terms of Service
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>NEED HELP WRITING YOUR TERMS OF SERVICE?<br/>
                <p>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need GDPR help.'> dex@fanbaseconsulting.com</a>.</p>
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>Consumer facing internet companies post Terms of Service to disclose the rules of the platform and to avoid potential litigation from users.
                    Terms of Service are a type of legal agreement between the provider of a service to a person who wants to use that service. Terms of Service are necessary because they protect a Company’s content from potential copyright infringement, protect against potential liabilities, and protect against malicious behaviors.
                    <ol className="privacy_text-ol">
                    
                </ol>
                At Fanbase we write, review and update Terms of Services by considering the legal jurisdiction a company is incorporated, any connected third parties, and the specific type of consumer facing technology that you have built. 

                </p>
                <ol className="privacy_text-ol">
                    
                </ol>
                <p>
                </p>
                <p><i><b>Need Help with your Terms of Service? Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need GDPR help.'> dex@fanbaseconsulting.com</a>.</b></i></p>
            </div>

        </div>
    );
};

export default Terms;