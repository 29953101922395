import React from 'react';
import './Jurisdictions.scss';
import CountryCards from "../../../../Components/CountryCards/CountryCards";
import Office from '../../../../img/office2.jpg'

const Jurisdictions = () => {
    return (
        <div className='site-block__wrapper jurisdictions'>
            <div className="jurisdictions__title home__service-categories__title__info">
            <span>Experience on Four Continents</span>
                <h2 className='mainTitle'>Experienced</h2>
                <p>We have the experience to assist you in jursidictions across the globe. Generally, the listed jurisdictions are optimal for the co-listed industries.
                    Unsure? Contact us anywhere.
                </p>
            </div>
            <div className="jurisdictions__content">
                <CountryCards image={Office}  img={Office}/>
            </div>
        </div>
    );
};

export default Jurisdictions;
