const linksList = [
    {
        stateName: 'ALABAMA',
        stateNameLink: 'www.asc.state.al.us',
        transferName: 'https://asc.alabama.gov/Registration%2520Filing%2520Req/soc7a_revised_filing_req.aspx&sa=D&ust=1595861925330000&usg=AOvVaw1xPsFL-z3Dx0jg559QDcrW',
        transferNameLink: 'https://asc.alabama.gov/Registration%2520Filing%2520Req/soc7a_revised_filing_req.aspx&sa=D&ust=1595861925330000&usg=AOvVaw1xPsFL-z3Dx0jg559QDcrW'
    },
    {
        stateName: 'ALASKA',
        stateNameLink: 'https://www.commerce.alaska.gov/web/',
        transferName: 'Money Service Businesses, Consumer Finance',
        transferNameLink: 'https://www.commerce.alaska.gov/web/dbs/ConsumerFinance/MoneyServiceBusinesses.aspx'
    }, {
        stateName: 'ARIZONA',
        stateNameLink: 'https://dfi.az.gov/money-transmitters-dfi',
        transferName: 'Arizona Department of Financial Institutions: Home',
        transferNameLink: 'https://dfi.az.gov/'
    }, {
        stateName: 'ARKANSAS',
        stateNameLink: 'http://www.securities.arkansas.gov/page/339/money-services&sa=D&ust=1595861925331000&usg=AOvVaw3px-hM8mY4tY2tfad2OzAL',
        transferName: 'Arkansas Securities Department',
        transferNameLink: 'http://www.securities.arkansas.gov/&sa=D&ust=1595861925332000&usg=AOvVaw0nGhGjEpHqaGIbSmVxDZT4'
    },
    {
        stateName: 'CALIFORNIA',
        stateNameLink: 'https://dbo.ca.gov/money-transmitter-division/&sa=D&ust=1595861925332000&usg=AOvVaw2prbHI_sPzpkwwiDBucJ5S',
        transferName: 'The Department of Business Oversight | Securing a fair and healthy financial services marketplace',
        transferNameLink: 'http://www.dbo.ca.gov/&sa=D&ust=1595861925332000&usg=AOvVaw0xmWnDyWJ9jlzsVacDNR3n'
    },
    {
        stateName: 'COLORADO',
        stateNameLink: 'https://www.colorado.gov/pacific/dora/node/92991&sa=D&ust=1595861925332000&usg=AOvVaw2yxcBaM3k6asdIiwdUSS2u',
        transferName: 'Division of Banking | Department of Regulatory Agencies',
        transferNameLink: 'https://www.colorado.gov/dora/division-banking&sa=D&ust=1595861925332000&usg=AOvVaw1-i21dR4o768LLdTGIMXQk'
    }, {
        stateName: 'CONNECTICUT',
        stateNameLink: 'https://portal.ct.gov/DOB/Consumer-Credit-Licensing-Info/Consumer-Credit-Licensing-Information/Money-Transmitter-Licensing-Information&sa=D&ust=1595861925333000&usg=AOvVaw25ZiRTDnddq4-xpdrD-3hz',
        transferName: 'http://www.ct.gov/dob/site/',
        transferNameLink: 'https://www.google.com/url?q=http://www.ct.gov/dob/site/&sa=D&ust=1595861925333000&usg=AOvVaw12OaINY5itAt9elIv-U8ID'
    },
    {
        stateName: 'DELAWARE',
        stateNameLink: 'https://banking.delaware.gov/apply-for-a-license/&sa=D&ust=1595861925333000&usg=AOvVaw1MygiC8prDtngasPHc5QBf',
        transferName: 'Office of the State Bank Commissioner - State of Delaware',
        transferNameLink: 'http://banking.delaware.gov/&sa=D&ust=1595861925333000&usg=AOvVaw2MSrjij7qnjMAHrLfqQQrX'
    }, {
        stateName: 'DISTRICT OF COLUMBIA',
        stateNameLink: 'https://disb.dc.gov/node/318732&sa=D&ust=1595861925334000&usg=AOvVaw3s0t_NKJza_2ARgjSXDaL3',
        transferName: 'http://disb.dc.gov/',
        transferNameLink: 'http://disb.dc.gov/&sa=D&ust=1595861925334000&usg=AOvVaw3yZY6HicGbbOqUqS6tQ9Mf'
    }, {
        stateName: 'FLORIDA',
        stateNameLink: 'https://www.flofr.com/sitePages/MoneyTransmitters.htm&sa=D&ust=1595861925334000&usg=AOvVaw0s_tsRKNX5KiC2YchtFY6q',
        transferName: 'http://www.flofr.com/',
        transferNameLink: 'http://www.flofr.com/&sa=D&ust=1595861925334000&usg=AOvVaw2zd-6JUklwSrn0HZiIpAch'
    }, {
        stateName: 'GEORGIA',
        stateNameLink: 'https://dbf.georgia.gov/money-transmitters&sa=D&ust=1595861925335000&usg=AOvVaw27u3VifOI86dTfegctv6L9',
        transferName: 'http://dbf.georgia.gov/',
        transferNameLink: 'http://dbf.georgia.gov/&sa=D&ust=1595861925335000&usg=AOvVaw2s_YxgnBrGVjSSJKzu0TVy'
    },
    {
        stateName: 'GUAM',
        stateNameLink: 'https://www.guamtax.com/about/regulatory.html&sa=D&ust=1595861925335000&usg=AOvVaw1Xs6eUF-L3u1TX6TmQIjW7',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'HAWAII',
        stateNameLink: 'http://cca.hawaii.gov/dfi/learn-more-about-money-transmitters/&sa=D&ust=1595861925335000&usg=AOvVaw2IE3RLExFSvfcfSXZ4zRcc',
        transferName: 'Financial Institutions',
        transferNameLink: 'http://cca.hawaii.gov/dfi/&sa=D&ust=1595861925336000&usg=AOvVaw06xLmQeSx-Hfg0e6pkK-KK'
    },
    {
        stateName: 'IDAHO',
        stateNameLink: 'https://www.finance.idaho.gov/who-we-regulate/money-transmitters/&sa=D&ust=1595861925336000&usg=AOvVaw0NJKuyTgRhjByRTe6-4WE1',
        transferName: 'http://www.finance.idaho.gov/',
        transferNameLink: 'http://www.finance.idaho.gov/&sa=D&ust=1595861925336000&usg=AOvVaw0mQvzrjDZL_VmlcY_n9WBO'
    },
    {
        stateName: 'ILLINOIS',
        stateNameLink: 'https://www.idfpr.com/DFI/CED/ced_main.asp&sa=D&ust=1595861925337000&usg=AOvVaw3X43K4lzYX1NePNZi-GFqR',
        transferName: 'State of Illinois | Department of Financial & Professional Regulation',
        transferNameLink: 'http://www.idfpr.com/&sa=D&ust=1595861925337000&usg=AOvVaw3TvwoTfaBmZcp5Pcs7V5I8'
    },
    {
        stateName: 'INDIANA',
        stateNameLink: 'https://www.in.gov/dfi/2762.htm&sa=D&ust=1595861925337000&usg=AOvVaw1JEPw2_MOzs6vexDZEzxDk',
        transferName: 'DFI: Home',
        transferNameLink: 'http://www.in.gov/dfi/&sa=D&ust=1595861925337000&usg=AOvVaw2TunBjDjIYjhcJno82Ks4c'
    },
    {
        stateName: 'IOWA',
        stateNameLink: 'https://mortgage.nationwidelicensingsystem.org/slr/Pages/DynamicLicenses.aspx?StateID%3DIA&sa=D&ust=1595861925338000&usg=AOvVaw241bBVwgFO_k21SCprvfGp',
        transferName: 'IDOB',
        transferNameLink: 'http://www.idob.state.ia.us/&sa=D&ust=1595861925338000&usg=AOvVaw17rsHhTuqCx6Cy_Xz7n0lZ'
    }, {
        stateName: 'KANSAS',
        stateNameLink: 'https://www.osbckansas.org/mt/applications.html&sa=D&ust=1595861925338000&usg=AOvVaw3kprkq0LLeyaB7HTdUwq_n',
        transferName: 'Office of the State Bank Commissioner of Kansas',
        transferNameLink: 'http://www.osbckansas.org/&sa=D&ust=1595861925338000&usg=AOvVaw0OWp8dS8m-IuXaN0dvIC6C'
    }, {
        stateName: 'KENTUCKY',
        stateNameLink: 'http://kfi.ky.gov/industry/Pages/transmitters.aspx&sa=D&ust=1595861925338000&usg=AOvVaw00Ea6LucdhTYj9bjKxrI4C',
        transferName: 'DEPARTMENT OF FINANCIAL INSTITUTIONS',
        transferNameLink: 'http://kfi.ky.gov/Pages/default.aspx&sa=D&ust=1595861925339000&usg=AOvVaw1v3TDqN4SGTzQM1uVTe9i5'
    }, {
        stateName: 'LOUISIANA',
        stateNameLink: 'http://www.ofi.state.la.us/SOC%2520Front.htm&sa=D&ust=1595861925339000&usg=AOvVaw13P5FWISvknZI81osxXUrn',
        transferName: 'Office of Financial Institutions',
        transferNameLink: 'http://www.ofi.state.la.us/&sa=D&ust=1595861925339000&usg=AOvVaw0kRWqrPwvStl-90cxlhLYH'
    },
    {
        stateName: 'MAINE',
        stateNameLink: 'https://www.maine.gov/pfr/consumercredit/licensing/money_transmitter/index.htm&sa=D&ust=1595861925340000&usg=AOvVaw2QiJmkaRGY23hZxpq7zopZ',
        transferName: 'Home Page : Bureau of Consumer Credit Protection',
        transferNameLink: 'http://www.maine.gov/pfr/consumercredit/&sa=D&ust=1595861925340000&usg=AOvVaw18hqQJT0T-LBxXMfQsIr_v'
    },
    {
        stateName: 'MARYLAND',
        stateNameLink: 'http://www.dllr.state.md.us/finance/industry/moneytran.shtml&sa=D&ust=1595861925340000&usg=AOvVaw2hpyBIb8Ni0N6iWq2T-ioE',
        transferName: 'Maryland Department of Labor',
        transferNameLink: 'http://www.dllr.state.md.us/&sa=D&ust=1595861925340000&usg=AOvVaw1Jk7VU1qlPxY5JL09VGPjx'
    }, {
        stateName: 'MASSACHUSETTS',
        stateNameLink: 'https://www.mass.gov/info-details/division-of-banks-licensee-information%23money-transmitter-license-&sa=D&ust=1595861925341000&usg=AOvVaw17_p5WwRcn0TDJok0_noFS',
        transferName: 'Division of Banks',
        transferNameLink: 'http://www.mass.gov/ocabr/government/oca-agencies/dob-lp/&sa=D&ust=1595861925341000&usg=AOvVaw1dSgFnlY-YffAj1S-hZaag'
    }, {
        stateName: 'MICHIGAN',
        stateNameLink: 'https://www.michigan.gov/difs/0,5269,7-303-79189_43095---,00.html&sa=D&ust=1595861925341000&usg=AOvVaw2crVYJvnL5s-1RjYs3M1ec',
        transferName: 'LARA - MI Department of Licensing and Regulatory Affairs',
        transferNameLink: 'http://www.michigan.gov/lara&sa=D&ust=1595861925341000&usg=AOvVaw16kjhLDegSmshOs4wHgvwN'
    }, {
        stateName: 'MINNESOTA',
        stateNameLink: 'https://mn.gov/commerce/industries/financial-institutions/money-transmission/&sa=D&ust=1595861925342000&usg=AOvVaw2B5fxi-0QuVUNqJXSwpUUN',
        transferName: 'Commerce Home / Minnesota.gov',
        transferNameLink: 'http://mn.gov/commerce/&sa=D&ust=1595861925342000&usg=AOvVaw3bgKm5TgezbSywFH_yJY5q'
    },
    {
        stateName: 'MISSISSIPPI',
        stateNameLink: 'https://www.dbcf.ms.gov/&sa=D&ust=1595861925342000&usg=AOvVaw2Lbvt2A_exEoLwrZIQ0Dzm',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'MISSOURI',
        stateNameLink: 'http://finance.mo.gov/&sa=D&ust=1595861925343000&usg=AOvVaw2OVOXgbGZOA2wGsJ1Ox0Kc',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'MONTANA',
        stateNameLink: 'http://banking.mt.gov/&sa=D&ust=1595861925343000&usg=AOvVaw1AwLaTMjXhhX__QwmW7v5j',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'NEBRASKA',
        stateNameLink: 'http://www.ndbf.ne.gov/&sa=D&ust=1595861925343000&usg=AOvVaw1FVyZaBbAMthY4Mvm0gNXq',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'NEVADA',
        stateNameLink: 'http://fid.nv.gov/Licensing/Money_Transmitter/ISSUERS_OF_INSTRUMENTS_FOR_TRANSMISSION_OR_PAYMENT_OF_MONEY/&sa=D&ust=1595861925343000&usg=AOvVaw1C24N9K3kgaGrx357mhI39',
        transferName: 'http://fid.nv.gov/',
        transferNameLink: 'http://www.fid.state.nv.us/&sa=D&ust=1595861925344000&usg=AOvVaw3RW1DuVUrhlmiF_q2vpPIn'
    },
    {
        stateName: 'NEW HAMPSHIRE',
        stateNameLink: 'http://www.nh.gov/banking/&sa=D&ust=1595861925344000&usg=AOvVaw1uecamwDwfedS4f6uDwM0C',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'NEW JERSEY',
        stateNameLink: 'https://www.state.nj.us/dobi/banklicensing/formontrans.html&sa=D&ust=1595861925344000&usg=AOvVaw1zjV1lRMqdC9g7SESM4GQh',
        transferName: 'New Jersey Department of Banking and Insurance',
        transferNameLink: 'http://www.state.nj.us/dobi/&sa=D&ust=1595861925344000&usg=AOvVaw3-wpcMCeTkkv4bNzbKwU7B'
    }, {
        stateName: 'NEW MEXICO',
        stateNameLink: 'http://www.rld.state.nm.us/&sa=D&ust=1595861925345000&usg=AOvVaw3wOzN0RqtctGx8OWiZrSGb',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'NEW YORK',
        stateNameLink: 'https://www.dfs.ny.gov/apps_and_licensing/money_transmitters&sa=D&ust=1595861925345000&usg=AOvVaw3K_iF9VlbG34-541T6zhR0',
        transferName: 'Home | Department of Financial Services',
        transferNameLink: 'http://www.dfs.ny.gov/&sa=D&ust=1595861925345000&usg=AOvVaw1bF76AUNPZ5RR6HQyPEo1E'
    }, {
        stateName: 'NORTH CAROLINA',
        stateNameLink: 'http://www.nccob.gov/public/&sa=D&ust=1595861925345000&usg=AOvVaw0VxqePM78L5SFHS5qR1n5s',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'NORTH DAKOTA',
        stateNameLink: 'http://www.nd.gov/dfi/&sa=D&ust=1595861925346000&usg=AOvVaw3_H3N6FoY7dxLGsFgg05N0',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'NORTHERN MARIANA ISLANDS',
        stateNameLink: 'http://commerce.gov.mp/&sa=D&ust=1595861925346000&usg=AOvVaw2e44nCCUI4LxHPDapFDOX5',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'OHIO',
        stateNameLink: 'http://www.com.state.oh.us/&sa=D&ust=1595861925346000&usg=AOvVaw1A1nJVtFfMYVN8nx-Yp7J_',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'OKLAHOMA',
        stateNameLink: 'http://www.ok.gov/banking/&sa=D&ust=1595861925346000&usg=AOvVaw0Cf6_WTlhX82FEH6CbJ9go',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'OREGON',
        stateNameLink: 'http://www.cbs.state.or.us/external/dfcs/&sa=D&ust=1595861925347000&usg=AOvVaw3VlO96_4hK_jjuDKxp3aZZ',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'PENNSYLVANIA',
        stateNameLink: 'http://www.dobs.pa.gov/&sa=D&ust=1595861925347000&usg=AOvVaw2CdBznp7bBJkHEdh3YWyUo',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'PUERTO RICO',
        stateNameLink: 'https://nationwidelicensingsystem.org/slr/PublishedStateDocuments/PR-Money-Transmitter-License-Company-New-App-Checklist.pdf&sa=D&ust=1595861925347000&usg=AOvVaw2tK_gVeC5lUzZuOiiwJdBg',
        transferName: 'OCIF',
        transferNameLink: 'http://www.ocif.pr.gov/&sa=D&ust=1595861925347000&usg=AOvVaw0nC_tdE_pzumC3aNoQHNa2'
    },
    {
        stateName: 'RHODE ISLAND',
        stateNameLink: 'http://www.dbr.state.ri.us/&sa=D&ust=1595861925348000&usg=AOvVaw2wHEe-1yVjevGg86KY4vyj',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'SOUTH CAROLINA',
        stateNameLink: 'http://www.consumerfinance.sc.gov/&sa=D&ust=1595861925348000&usg=AOvVaw144YyR3qA8YoCMHtzu0Umc',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'SOUTH DAKOTA',
        stateNameLink: 'http://dlr.sd.gov/banking/&sa=D&ust=1595861925348000&usg=AOvVaw0qbEGvMpEkoZBLJMf_1f0E',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'TENNESSEE',
        stateNameLink: 'http://www.tennessee.gov/tdfi/&sa=D&ust=1595861925348000&usg=AOvVaw1nmmpNLAWFUJpJBYRIOpsJ',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'TEXAS',
        stateNameLink: 'https://www.dob.texas.gov/money-services-businesses&sa=D&ust=1595861925349000&usg=AOvVaw1PHfJphPPwG-Ylf8Q0kZLf',
        transferName: 'Texas Department of Banking',
        transferNameLink: 'http://www.dob.texas.gov/&sa=D&ust=1595861925349000&usg=AOvVaw1dBpbgfu-LRluIqyTzq-vm'
    }, {
        stateName: 'US VIRGIN ISLANDS',
        stateNameLink: 'https://ltg.gov.vi/departments/banking-insurance-and-financial-regulation/&sa=D&ust=1595861925349000&usg=AOvVaw2QvfZrsEF1dHOeFwuD7KiZ',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'UTAH',
        stateNameLink: 'http://dfi.utah.gov/&sa=D&ust=1595861925349000&usg=AOvVaw1jMRQpkNT0kQZinQcKJCAr',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'VERMONT',
        stateNameLink: 'http://www.dfr.vermont.gov/&sa=D&ust=1595861925350000&usg=AOvVaw39dKALWNkeovsRtsccFthN',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'VIRGINIA',
        stateNameLink: 'http://www.scc.virginia.gov/&sa=D&ust=1595861925350000&usg=AOvVaw2YXd3Xpn_c5rVWt3n9uuna',
        transferName: '',
        transferNameLink: ''
    }, {
        stateName: 'WASHINGTON',
        stateNameLink: 'http://www.dfi.wa.gov/&sa=D&ust=1595861925350000&usg=AOvVaw0mShOITQaFQSdfXuBVoSEf',
        transferName: '',
        transferNameLink: ''
    },
    {
        stateName: 'WEST VIRGINIA',
        stateNameLink: 'http://www.dfi.wv.gov/&sa=D&ust=1595861925350000&usg=AOvVaw3XuGkjzNQpYLShEda-pF0A',
        transferName: '',
        transferNameLink: ''
    },  {
        stateName: 'WISCONSIN',
        stateNameLink: 'http://www.wdfi.org/&sa=D&ust=1595861925351000&usg=AOvVaw0QkSuVH4w--SrNNZVN12eB',
        transferName: '',
        transferNameLink: ''
    },{
        stateName: 'WYOMING',
        stateNameLink: 'http://wyomingbankingdivision.wyo.gov/&sa=D&ust=1595861925351000&usg=AOvVaw2fCxYySTp2PD5bXxT9MpKA',
        transferName: '',
        transferNameLink: ''
    },


];


export default linksList