import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
// import Article01 from '../../img/articles/01.jpg';
// import Article02 from '../../img/articles/02.jpg';
import {Link} from "react-router-dom";
import logo from "../../img/CodipityBlckBlue.png";
import preview from './articlePreview';

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Articles
                </h1>
            </div>
            {preview.map((item, idx) =>
                <div key={idx} className="blog_flex-column site-block__wrapper diflex blog_flex-mobile">
                    <div className="blog_flex-column site-block__wrapper">
                        <img src={item.image} className="privacy-warning-img" alt="warning"/>
                    </div>
                    <div className='diflex-columns'>
                        <h3 className='blog-h3'>{item.title}</h3>
                        <p>{item.description}
                        </p>
                        <Link to={item.link}><p className='blog-readmore'>read more >></p></Link>
                        <h3 className='blog-h3'>{item.date}<br/>
                            {item.author}
                        </h3>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Blog;
