import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const BusinessPlan = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Business Plans
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>
                    DOES YOUR APPLICATION REQUIRE A BUSINESS PLAN?<br/>
                    If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help.'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
               

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>While corporate registrations generally do not require business plans, most license applications do. Whether you are applying as a new banking institution, 
                    a broker-dealer, a crowdfunding portal, a money transmitter, or a cryptocurrency company, 
                   you are generally required to submit a detailed business plan no matter the jusridiction, be it the US, EU or elsewhere. 
                   The sections of the plan vary slightly depending on the license and jurisdiction, but  
                   the Fanbase team has a digital questionaire designed for you to record some required information such as the ownership team, 
                   the executive team, and the beneficial owners, in order to streamline the process. 
                   
                   <br/><br/> Other sections include financial projections, competetive advantages, and marketing approaches.  If you can sketch the flow of money on a white board or sketch pad, 
                   we can add the necessary details and pair those details with our proven formats. If you are unsure, you can work with our tech team to describe what you intend to build,
                   and we can provide plans to match.  Our team has built massive technologies related to fintech ourselves. 
                  
                
                   We are technologists and scholars who have built large fintech systems and published research from world leading institutions, related to digital media and technology, and 
                   we have attorneys on staff to craft the necessary legal arguments. <br/><br/>
                   
                   <p>Other questions?<br/><br/>
                </p>
                <p><i><b>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a> for help with your business plan.</b></i></p>
            
                </p>
                
                
               
            </div>

        </div>
    );
};

export default BusinessPlan;