import './ServiceCategories.scss';
import serviceBg from '../../../../img/man-image.jpg';
import React from 'react';

const ServiceCategories = () => {
    return (
        <div className='home__service-categories'>
            <div className="home__service-categories__title">
                <div className="home__service-categories__title__info">
                    <span>Radical Transparency</span>
                    <h2 className='mainTitle'>Competent</h2>
                    <p> Fanbase can assist you with licensure in industries such as banking, crypto,
                        crowdfunding portals, broker-dealers and more. We are a competent team that provides transparent prices and exceptional value.
                    </p>
                </div>
                <img src={serviceBg} alt="serviceBg" className="home__service-categories__img"/>
            </div>
            <div className="home__service-categories__list">
                <div className="home__service-categories__list__item">
                    <h2>Financing</h2>
                    <div className="home__service-categories__list__item__info">
                        <p>We prepare documents for private placements, crowdfunding campaigns, Reg. D offerings, STOs,
                            ICOs and many other funding scenarios. In addition, we are official partners with a popular crowdfunding portal.
                            Using Fanbase to register your company guarentees review of your company by that portal's CEO, if you so choose.
                        </p>
                    </div>
                </div>
                <div className="home__service-categories__list__item">
                <h2 className="show-block">Accounting</h2>
                    <div className="home__service-categories__list__item__info" style={{marginLeft: '30px'}}>
                        <p>Some jursdictions require local accounting knowlege. Allow Fanbase to handle your local taxes
                           and accounting. We will do your year one taxes for a discounted flat fee and then quote you a price prior to year 2,
                           after getting to know your business, and understanding the scope of the work.

                        </p>
                    </div>
                    <h2 className="hide-block">Accounting</h2>

                </div>
                <div className="home__service-categories__list__item">
                    <h2>Licensing</h2>
                    <div className="home__service-categories__list__item__info">
                        <p> We can launch E-money institutions, crypto companies, payment services,
                            credit institutions, investment funds, banks, credit unions,
                            intermediaries, and much more.
                        </p>
                    </div>
                </div>
                <div className="home__service-categories__list__item">
                <h2 className="show-block">Consulting</h2>
                    <div className="home__service-categories__list__item__info" style={{marginLeft: '30px'}}>
                        <p> We have attorneys on staff who can provide legal advice and we have prepared countless legal documents for clients. We can also help you with white papers, power of attorney (PoA), non-disclosures (in chinese and english),
                            privacy policies, business plans, terms and conditions, agreements of sale, employment contracts & GDPR, to name a few.


                        </p>
                    </div>
                    <h2 className="hide-block">Consulting</h2>

                </div>
            </div>
        </div>
    );
};

export default ServiceCategories;
