import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const PrivatePlacement = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Private Placement
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>NEED HELP WITH A PRIVATE PLACEMENT?<br/>
                <p>If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help with a Private Placement...'> dex@fanbaseconsulting.com</a>.</p>
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>A Private Placement Memorandum (PPM) is a legal document which facilitates the sale of securities. The purpose of this document is to legally solicit funds from early-stage investors. Generally, in the United States, a PPM formalizes this process and is conducted under Reg. D rules. There are stipulations to qualify as an investor based on income level. 
                    Additionally there are rules about the number of investors a company can accept, based on those income levels.
                    The PPM document details the type of business, and its potential revenue streams. 
                    It also describes the management structure, the type of investment, the amount, and the risks.  
                    At Fanbase, we provide Private Placement consultations and PPM documents.  
</p>
                <ol className="privacy_text-ol">
                    
                </ol>
                
                <p><i><b>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help writing a white paper...'> dex@fanbaseconsulting.com</a> for help with your Private Placement.</b></i></p>
            </div>

        </div>
    );
};

export default PrivatePlacement;
