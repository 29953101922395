import React from 'react';
import './CallbackForm.scss';
import {Checkbox} from "antd";
import {Link} from "react-router-dom";

const CallbackForm = () => {
    return (
        <div className="callback-form__wrapper">
            <div className="callback-form">
                <h3>Request a Whatsapp message</h3>
                <input type="phone" placeholder='Phone number'/>
                <input type="text" placeholder='Your name'/>
                <Checkbox/> I'm not a robot
                <Link to='/' className='primaryBtn'>Request</Link>
            </div>
        </div>
    );
};

export default CallbackForm;