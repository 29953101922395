import './Global.scss';
import React from 'react';
import {Link} from "react-router-dom";
import GlobalOutlined from "@ant-design/icons/lib/icons/GlobalOutlined";
import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import GlobalImg from '../../../../img/global_bg.jpg'

const Global = () => {
    return (
        <div className='home__global'>
            <div className="home__global__title__wrapper">
                <div className="home__global__title">
                    <span>In a Global Economy</span>
                    <h2 className='mainTitle'>Distributed</h2>
                </div>

            </div>
            <p className='home__global__title__info'>
                Fanbase maintains a global workforce and flat rates so that you avoid exhorbitant legal fees for what
                often amounts to document preparation.
                With offices in the US and EU and partners around the world, we have the education, experience, and
                footprint to tackle licensures in nearly all jurisdictions.
            </p>
            <div className="home__global__mission-block" style={{"background": `url(${GlobalImg}) 40%`}}>
                <div className="home__global__card">
                    <ClockCircleOutlined/>
                    <h3>The Right Time</h3>
                    <p>There is no better time than today to incorporate your organization or submit your business
                        inquiry to Fanbase.
                        We handle the paperwork so that you can get back to innovating!

                    </p>
                </div>
            </div>
            <div className="home__global__vision">
                <div className="home__global__card">
                    <GlobalOutlined/>
                    <h3>The Right Place</h3>
                    <p>There is no better home for your company than Fanbase. We are the global leader in company
                        services with offices in the EU and US. We provide entrepreneurs
                        amazing tools and transparent pricing so that there are zero surprises.
                    </p>
                </div>
                <div className="home__global__vision__info">
                    <div className="home__global__vision__info__item">
                        <h3>Amazing Tools</h3>
                        <p>We are connected with government agencies and banks in various jurisdictions and we have
                            legal professionals and accountants on staff, yet we are distributed and lean so we can pass
                            those savings on to you.
                            Our clients enjoy the amazing business advisory and staffing tools we provide. We aren't
                            just a consultancy, in the traditional sense, because we also do the work!

                        </p>
                    </div>
                    <div className="home__global__vision__info__item">
                        <h3>Transparent Pricing</h3>
                        <p> We provide flat rate fees to perform your document preparation, corporate registration, and
                            local staffing tasks.
                            Unlike law firms who are incentivized to string you along, Fanbase's transparent, flat rate
                            pricing syncs our goals with our clients'. We want to help you thrive, not bleed you dry.
                            Therefore, you will never pay more than our flat rate.


                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Global;
