import './Footer.scss';
import logo from '../../img/Fanbase_logo.png';
import mearsyLogo from '../../img/smallLogo.png';
import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Input} from 'antd';
import MediumOutlined from "@ant-design/icons/lib/icons/MediumOutlined";
import LinkedinOutlined from "@ant-design/icons/lib/icons/LinkedinOutlined";
import searchLinkTool from  '../Search/SearchTool'

const {Search} = Input;

const Footer = () => {


    const [showSearch, setShowSearch] = useState(false);
    const [searchResult, setSearchResult] = useState(false);

    return (
        <div className='footer__wrapper mob-flex'>
            <div className="footer__top ">
                <div className="footer-clm-flex ">
                <div className="footer__top__item mob-left-block ">
                    <h2>Start-ups</h2>
                    <p>The Premier Partner for Technology, Media, Finance & Crypto Companies Looking to Register, License and Bank in the EU or US.</p>
                    <img src={logo} alt="logo" className="hide-block"/>
                    <p className="footer-logo-text"></p>
                </div>
                <div className="footer__top__item mob-right-block">
                    <h2>Menu</h2>
                    <Link to='/about'> Team</Link>
                    <Link to='/partnerships'> Partnerships</Link>
                </div>
                </div>
                <div className="footer-clm-flex">
                <div className="footer__top__item mob-left-block">
                    <h2>Documents</h2>
                    <Link to='/artist'> Artist Contracts</Link>
                    <Link to='/aml'> AML & KYC</Link>
                    <Link to='/gdpr'> GDPR</Link>
                    <Link to='/white-papers'> White Papers</Link>
                    <Link to='/business-plan'>Business Plans</Link>
                    <Link to='/financing'> Financing (Reg. D, etc.)</Link>
                    <Link to='/company-registration'>Company Registrations</Link>
                    <Link to='/terms'> Terms of Service</Link>
                    <Link to='/privacy'>Privacy Policies</Link>
                    <Link to='/private-placement'>Private Placements</Link>
                    <Link to='/power-attorney'>Power of Attorney</Link>
                    <Link to='/saft'>SAFT</Link>
                    </div>
                    <div className="footer__top__item mob-right-block">
                    <h2>Services</h2>
                    <Link to='/accounting'>Accounting</Link>
                    <Link to='/registration'> Registrations</Link>
                    <Link to='/freelacencers'>Freelancers</Link>
                    {/* <div className="footer__top__item show-block"> */}
                    {/* <h2 className="show-block">Information</h2> */}
                    <Link to='/blog' className="show-block articles-link">Expert Articles</Link>
                    {/* </div> */}
                    <div className="footer__top__item show-block articles-link mob-margin">
                    <h2>Contact Us:</h2>

                        <b>EU Office (HQ) </b>
                    <p>Girulių g. 20</p>
                    <p>Vilnius 12123, Lithuania.</p>
                    {/*<p>_____________</p>*/}
                    {/*   <b>US Office</b>*/}
                    {/*   <p>1811 N. Howard St.</p>*/}
                    {/*   <p>Philadelphia PA, 19122.</p>*/}
                    {/*   <p>dex@fanbaseconsulting.com</p>*/}
                </div>
                </div>
                </div>
                <div className="footer-clm-flex">
                <div className="footer__top__item hide-block">
                    <h2 className="hide-block">Information</h2>
                    <Link to='/blog' className="hide-block">Expert Articles</Link>
                    {/*<Search placeholder="Search..."*/}
                    {/*        onSearch={value => searchLinkTool(value, setSearchResult)}*/}
                    {/*        className=''*/}
                    {/*/>     */}
                {/*    {searchResult &&*/}

                {/*searchResult.map((searchResult, idx) =>*/}
                {/*    <div key={idx} className="search_result_line">*/}
                {/*        <Link to={searchResult.link} onClick={() => {*/}
                {/*            setSearchResult(false);*/}
                {/*            setShowSearch(false)*/}
                {/*        }}>{searchResult.name}</Link>*/}
                {/*    </div>*/}
                {/*)}*/}
                </div>

                <div className="footer__top__item hide-block">
                    <h2>Contact Us:</h2>


                    <b>EU Office (HQ) </b>
                    <p>Girulių g. 20</p>
                    <p>Vilnius 12123, Lithuania.</p>
                    {/*<p>_____________</p>*/}
                    {/*   <b>US Office</b>*/}
                    {/*   <p>1811 N. Howard St.</p>*/}
                    {/*   <p>Philadelphia PA, 19122.</p>*/}
                    {/*   <p>dex@fanbaseconsulting.com</p>*/}
                </div>
                </div>
            </div>
            <div className="footer__bottom">
                <div className="footer__bottom__info">
                    <div className="footer__bottom__info__doc">
                        <div className='footer__bottom__info__copy'><Link to='/'>Fanbase Network </Link></div>
                        <Link to='/codipityprivacypolicy'>Privacy Policy</Link><span className='footer_vertical-line'>&emsp;&emsp;</span>
                    </div>
                    <div className="footer__bottom__info__social">
                        <a href='https://www.linkedin.com/company/fanbasesmartchain/' target='_blank'><LinkedinOutlined/></a>
                        <a href='https://www.bonjourno.com/groups/60636aa308144b00174b10c0' target='_blank'><img src={mearsyLogo} alt="MearsY"/></a>
                    </div>
                </div>
                <div className="footer__bottom__info__copyright">
                    {/*<Link to='/'>Codipity LLC</Link> 2020. */}
                </div>
            </div>
        </div>
    );
};

export default Footer;
