import './Greetings.scss';
import React from 'react';
import {Link} from "react-router-dom";

const Greetings = () => {
    return (
        <div className="home__greeting__wrapper">
            <div className="home__greeting"/>
            <div className="home__greeting__message">
                <div className='home__greeting__blur'/>
                <h1>
                    The right <span>place </span>

                    @ the right <span>time</span>
                </h1>
                <p>The Premier Partner for Technology, Media, Finance & Crypto Companies Looking to Register, License and Bank
                    in the EU or US.</p>
                <a className='primaryBtn home__greeting__btn'
                   href='mailto:dex@fanbaseconsulting.com?subject=Hello, I want to register a new company or discuss something similar.'>Register
                    a Company</a>
            </div>
        </div>
    );
};

export default Greetings;
