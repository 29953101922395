import ArticleTips from "./Pages/Articles/ArticleTips/ArticleTips";
import ArticleStartEu from "./Pages/Articles/ArticleStartEu/ArticleStartEu";
import ArticleStartEu2 from "./Pages/Articles/ArticleStartEu2/ArticleStartEu2";
import ArticleTips3 from "./Pages/Articles/ArticleTips3/ArticleTips3";
import ArticleTips4 from "./Pages/Articles/ArticleTips4/ArticleTips4";
import ArticleTips5 from "./Pages/Articles/ArticleTips5/ArticleTips5";
import ArticleTips6 from "./Pages/Articles/ArticleTips6/ArticleTips6";
import ArticleTips7 from "./Pages/Articles/ArticleTips7/ArticleTips7";


const articleRoutes = [
    {
    link: "/article-tips",
    component: ArticleTips,
},
    {
    link: "/article-starting-eu",
    component: ArticleStartEu,
},{
    link: "/article-starting-eu2",
    component: ArticleStartEu2,
},{
    link: "/article-tip-3",
    component: ArticleTips3,
},{
    link: "/article-tip-4",
    component: ArticleTips4,
},{
    link: "/article-tip-5",
    component: ArticleTips5,
},{
    link: "/article-tip-6",
    component: ArticleTips6,
},{
    link: "/article-tip-7",
    component: ArticleTips7,
}
];

export default articleRoutes;