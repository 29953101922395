import './Business.scss';
import React from 'react';
import CountryCards from "../../../../Components/CountryCards/CountryCards";
import OfficeImage from '../../../../img/office3.jpg'

const Business = () => {
    return (
        <div className='home__business'>
            <div className="home__business__title home__service-categories__title__info">
                <h2 className='mainTitle'>Consultation</h2>
                <p>Generally, the listed jurisdictions are optimal for the co-listed industries. Unsure? Contact us anywhere for a business consultation.</p>
            </div>
            <CountryCards img={OfficeImage}/>
        </div>
    );
};

export default Business;
