import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'

const GDPR = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>GDPR
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>NEED HELP UNDERSTANDING OR WRITING GDPR LANGUAGE?<br/>
                If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need GDPR help.'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>If you own a company which collects and stores user data, like names or ages, you probably utilize a database, control user data, and are therefore required by EU law to have in place specific processes to protect that data. Generally,
                businesses which collect data are now required to design systems that place user privacy above all other considerations and processes. Specifically, if you conduct business in the EU, data hosts are now required to adhere to protocals which safeguard  
                user data, according to the GDPR (General Data Protection Regulation), as established by the European Union Parliament and Council. 
                <ol className="privacy_text-ol">
                    
                </ol>
                The aim of the GDPR legislation is to improve digital privacy. The point of this legislation is to allow EU residents to have more control over their personal data. If you comply with GDPR, you reduce your legal liability if a data breach occurs. In order to reduce liability, your company must properly collect, secure and destroy personal data. Fanbase 
                provides a GDPR compliance service and can consult you on using proper GDPR language. Our team of engineers can also provide you examples of how to structure your admin panels and data collection processes when you are preparing your business for EU customers.

                
                </p>
                <ol className="privacy_text-ol">
                    
                </ol>
                <p>If you plan to conduct business in the EU, and host user data, contact us for a free consultation.
                </p>
                <p><i><b>Need Help with GDPR? Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need GDPR help.'> dex@fanbaseconsulting.com</a>.</b></i></p>
            </div>

        </div>
    );
};

export default GDPR;