import './Comments.scss';
import quote from '../../../../img/quote.png';
import React, {useRef, useState} from 'react';
import {Carousel} from "antd";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";

const Comments = () => {
    const slider = useRef();

    const names = []

    const [currentName, setCurrentName] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(false);

    const activateBtn = () => {
        setTimeout(() => {
            setDisabledBtn(false)
        }, 500)
    };

    const nextSlide = type => {
        setDisabledBtn(true)

        const idx = names.indexOf(currentName);

        if (idx === 3 && type === 'prev') {
            slider.current.prev()
            setCurrentName(names[0]);
            activateBtn()
        } else if (type === 'prev') {
            slider.current.prev()
            setCurrentName(names[names.indexOf(currentName) + 1]);
            activateBtn()
        } else if (idx <= 0 && type === 'next') {
            slider.current.next()
            setCurrentName(names[3]);
            activateBtn()
        } else if (type === 'next') {
            slider.current.next();
            setCurrentName(names[names.indexOf(currentName) - 1]);
            activateBtn()
        }
    }

    return (
        <div className='home__comments'>
            <div className="home__comments__content">
                <div className="home__comments__content__title">
                    <span>Your Direct Legal Path</span>
                    <h2 className='mainTitle'>Committed</h2>
                </div>
                <Carousel className='home__comments__content_carousel'
                          dots={false}
                          ref={ref => slider.current = ref}
                >
                    <div className='home__comments__content_carousel__item'>
                        
                        <p> Fanbase can launch your Lithuanian company at light speed. We can set up your office, hire your board members, and provide qualified Anti-Money Laundering professionals. Why relocate your company when you can just use Fanbase?!</p>
                    </div>
                    <div className='home__comments__content_carousel__item'>
                        
                        <p> Fanbase can launch your International Financial Entity, in Puerto Rico, in about 8 months.  
                            IFEs are widely regarded as the most advantageous bank entities, due to the low capital buy-in, access to the US system, and favorable local attitudes. To submit an application for an IFE contact us today!</p>
                    </div>
                    <div className='home__comments__content_carousel__item'>
                       
                        <p>
                            Fanbase can launch your US-based or British Virgin Island's Broker-Dealer. BD's in BVI are cost effective, 
                            yet restricted in some ways. BD's in the US generally require 2 founders with the Series 7, 63 and 24, and a half million dollars in capital. 
                            To avoid these roadblocks register an entity in BVI for one-fifth the capital. Contact us now!
                        </p>
                    </div>
                    <div className='home__comments__content_carousel__item'>
                        
                        <p> Fanbase can launch your E-money service (EMI) in Lithuania. EMIs in Lithuania are cost effective with a $350k capital requirement. 
                            To register an EMI in the EU like Revolut or N26, contact us today!</p>
                    </div>
                </Carousel>
                <p className='home__comments__content_carousel__name'>
                    {currentName}
                </p>
                <div className='home__comments__content_carousel__controls'>
                    <button onClick={() => nextSlide('prev')} disabled={disabledBtn}>
                        <LeftOutlined/>
                    </button>
                    <button onClick={() => nextSlide('next')} disabled={disabledBtn}>
                        <RightOutlined/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Comments;