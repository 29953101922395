import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const Saft = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>SAFT
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>NEED A SIMPLE AGREEMENT FOR FUTURE TOKENS?<br/>
                <p>If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need GDPR help.'> dex@fanbaseconsulting.com</a>.</p>
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>A Simple Agreement for Future Tokens (SAFT) is a contract that cryptocurrency developers offer to investors to help fund the development of a cryptocurrency technology with the promise of delivering the agreed upon amount of tokens to investors once the tokens have been created. 
                   It provides two benefits for the parties involved. It allows entrepreneurs to raise necessary funds and it allows entrepreneurs to avoid regulatory violations. Therefore, the SAFT provides investors a possible option for converting cash into equity if the creation of the token is a success.
                   We provide SAFT services at Fanbase. We help prepare the necessary paperwork for your cryptocurrency venture and check that your investors are accredited, based on your jurisdiction and income level. 

                </p>
                <ol className="privacy_text-ol">
                   
                </ol>
                <p>
                </p>
                <p><i><b>Contact dex@fanbaseconsulting.com for help with a SAFT. </b></i></p>
            </div>

        </div>
    );
};

export default Saft;
