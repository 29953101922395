import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const Freelacencers = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Freelancers
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>ARE YOU A FREELANCER LOOKING TO REGISTER?<br/>
                If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>If you are a freelancer or a digital nomad
                    you might want to incorporate your company in the EU to gain access to the large EU market, 
                    and the pro-business laws which favor digital companies.  E-registered companies often enjoy 
                    more favorable regulatory bodies for freelance activities. Similarly, some of our clients wish to incorporate in a US jurisdiction like Delaware, Wyoming, Nevada, or Puerto Rico. 
                    
                    
                </p>
                <br/>
                <p> 
                </p>
                <br/>
                <p> If you have a use case in which incorporating a company in the EU or US would be an advantage, and you need a local office,
                    we have addresses and teams available at a moment's notice depending on your needs. Normally, 
                    freelancers work independently, and remotely, but feel free to email us with questions about which jurisdiction might be right for your
                    company. 
                </p>
                <br/>
                <p>Fanbase has a footprint in many jurisdictions including the US and EU. We have broad experiences helping freelancers conduct business in jurisdictions across the globe.
                </p>
                <p><i><b>For more information, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'>
                dex@fanbaseconsulting.com</a>.</b></i></p>
            </div>

        </div>
    );
};

export default Freelacencers;
