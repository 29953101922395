import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'

const WhitePapers = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>White Papers
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>NEED HELP WRITING A WHITE PAPER?<br/>
                <p>If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need GDPR help.'> dex@fanbaseconsulting.com</a>.</p>
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>A White Paper is a document that is used to explain your business in intricate detail in the hopes of attracting employees and investors. At its core, a White Paper discusses  the problems you will solve, the technical hurdles, and your credibility as an entrepreneur. 
                    A White Paper is different from a business plan. It is semi-technical in nature and requires planning and research, even though it is often the first written concept of your idea. 
                    Fanbase can assist you in writing your White Paper.  We are a team of academics, engineers and lawyers who have published professionally for over a decade. 

                </p>
                <ol className="privacy_text-ol">
                    
                </ol>
                
                <p><i><b>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help writing a white paper...'> dex@fanbaseconsulting.com</a> for help with your white paper.</b></i></p>
            </div>

        </div>
    );
};

export default WhitePapers;