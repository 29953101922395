import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/eu2.jpg'
import ImageArticle01 from '../../../img/articles/eu2-1.jpg'
import ImageArticle02 from '../../../img/articles/eu2-2.png'
import ImageArticle03 from '../../../img/articles/eu2-3.png'

const ArticleStartEu = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>TIP #2: THE EU CRYPTO LICENSE PROCESS
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>JULY 15, 2020<br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com</a>
                </h3>
            </div>
            <div className="flex site-block__wrapper ">
                <img src={ImageArticle} className="privacy-warning-img-page leftimg"/>
                <div className='flex-column'>
                <p className='article-p-margin'>The process for getting a cryptocurrency license in the European Union
                    is easier than anywhere else
                    in the world. If you are an entrepreneur, investor, financial analyst or researcher, you may have
                    confronted stringent or bureaucratic procedures associated with obtaining such licenses in your own
                    country and not realized that in some parts of the world, licensure is much easier. There are, in
                    fact, some governments that act logically, and in the interest of their own citizens, rather than in
                    the interest of the donor class and the big banks. Sometimes derided as too big to fail, those banks
                    are propped up by government policies that make citizen’s worst nightmares come true when global
                    economies collapse.
                    If you happen to be living in such a country, under such as system, and
                    you are looking for a
                    solution to the legal conundrum confronting you and your cryptocurrency company, we have found the
                    perfect place for your company to register- a place where there are minimal bureaucratic
                    incongruities and zero negative perceptions of digital assets. A place where, in fact, the digital
                    revolution is embraced in all its forms. Well, can you guess where that place is?!
                </p>   <p style={{'textAlign': 'center', 'fontSize': '22px'}} className='article-p-margin'>Wonder, no more. Welcome to Lithuania!
                </p>
                </div>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p className='article-p-margin'>Lithuania is a small country in Northern Europe that is a member state of
                    the European Union. And,
                    Lithuania is open for business! </p>
                <p className='article-p-margin'>
                    In this article we will describe the process of obtaining the required license from the EU member
                    state of Lithuania, in a transparent, step-by-step manner, which will allow you to open, legally, in
                    the entire EU market, and in many other parts of the world including some areas in Asia and South
                    America. Luckily, it is our opinion at Fanbase LLC, the global leader in Crypto and E-money
                    Corporate Registrations, that Lithuania is by far the best jurisdiction in Europe, and probably the
                    world, for gaining a crypto license. Compared to the less than welcoming business conditions crypto
                    companies find in most parts of the world, and the archaic money transmitter licensing system in the
                    United States, Lithuania is an easy choice for the best place to register your bootstrapped crypto
                    company. That is not to say there have not been some setbacks in Lithuania, which have left some
                    startups scrambling.

                </p>
                <p className='article-p-margin'>In case you have not heard, on March 10th 2020, new Anti-Money
                    Laundering rules went into effect with
                    regards to cryptocurrency licensed operations in Lithuania. Although not enough to stop you in your
                    pursuit of registering your global crypto company, these changes have created some beauractracic red
                    tape where there was none before. The starting point then, is to understand the process of business
                    registration for this type of company so that you can avoid any unnecessary red tape. This article
                    discusses:
                </p>

                <ul className="privacy_text-ol">
                    <li>
                        Legal basis for an entity needing the license
                    </li>
                    <li>
                        Requirements for the applicant
                    </li>
                    <li>
                        Cross border operations
                    </li>
                    <li>
                        Completion and submission of the application
                    </li>
                    <li>
                        Payment of the state fee
                    </li>
                    <li>
                        Processing of the application
                    </li>
                    <li>Changes to the conditions of the activity license</li>
                    <li>Revocation of such license</li>
                    <li>Supervision as well as contact information</li>
                </ul>
                <br/>
                <p>Understanding the process is key. In Lithuania, a cryptocurrency business requires an Activity License
                    for the Provision of Financial Services, under which the Provision of Virtual Currency Services is
                    issued by the Financial Intelligence Unit. </p>
                <br/>
                <p>To obtain the license, you must register your company (or a subsidiary or parent of your company), as
                    an LLC in Lithuania. Most entrepreneurs e-register their company, however, this is not compulsory,
                    because obtaining the license may be done through a Power of Attorney at a local branch office. For
                    a foreign company registering an affiliate in Lithuania, as opposed to foreign persons registering a
                    company, the process for obtaining a license is more complicated, because additional notarized
                    documents are required ensuring your existing corporation is on good ethical and financial terms, in
                    your current jurisdiction. The prerequisites to registering your Lithuanian crypto company are as
                    follows:</p>
                <ol className="privacy_text-ol">
                    <li>
                        <b>Hire your Management Board.</b> The registration of the company is contingent on having a
                        management
                        board member, and often, a compliance officer, who resides in Lithuania. As described in the
                        Income Tax Act, these individuals must have good business reputations. In some cases, the
                        compliance officer may live elsewhere, however, it might be necessary for her to go to Lithuania
                        for an in-person interview.
                    </li>
                    <li>
                        <b>Register your Business.</b> Business registration is one of the essential criteria for
                        obtaining the license. To do so, you are required to be physically present, and have an office
                        space or place of business in Lithuania. Establishing a place of business is essential as
                        financial regulators may, from time to time, check on whether or not your listed office address
                        is accurate. You need only one person at this office location; this is generally the duty of the
                        local Board Member, who should be accessible at this place of business. This requirement,
                        however, does not apply to non-active board members.
                    </li>
                    <li><b>Pay your Service fee. </b>In order to apply for an activity licence for a provider of virtual
                        currency services, operating as a financial institution, a state fee of 3300 euros, has to be
                        paid to the Ministry of Finance, at Swedbank, SEB or Luminor. In making the payment, use
                        reference number <b>2900082498</b>, and include the name of your enterprise, with the words
                        “Application for an Activity Licence from the FIU".
                        <ul className="privacy_text-ol">
                            <li><b>Swedbank</b> - EE932200221023778606</li>
                            <li><b>SEB</b> - EE89101022003479 6011</li>
                            <li><b>Luminor</b> - EE701700017001577198</li>

                        </ul>
                    </li>
                    <li>
                        <b>Submit Evidence of a Non-criminal Record and Copies of your Passport.</b> Each shareholder of
                        the company must show evidence of a non-criminal record issued from their home country, as
                        certified by an apostille. The document must be issued within the past 3 months. Passports are
                        also required.

                    </li>
                    <li>
                        <b>Submit your Share Capital.</b> A minimum share capital of 12,000 euros is required.
                    </li>
                    <li><b>Submit your Bank Account.</b> A company bank account is required or a payment service
                        provider account in an EEA country which provides cross border services in Lithuania.
                    </li>
                    <li><b>Submit the Educational Background of each of the Members.</b>
                        An educational profile, such as a curriculum vitae, for each member of the company, is also
                        required.

                    </li>
                    <li><b>Submit the KYC/AML Procedures and Associated Protocols.</b> There are quite a few required
                        documents, including the know-your-customer and anti-money laundering procedures, that are
                        required for the registration process. Fanbase has these documents prepared, specifically
                        catered to Lithuanian regulations, which will save you hundreds of hours of work.
                    </li>
                </ol>
                <br/>
                <p>Note that, since the release of the new AML law for cryptocurrency license holders, a prospective
                    seeker of the license is required to show the list of payment accounts kept in the name of the
                    company, each payment account’s unique number, and the account manager’s name. </p>

                <h2 style={{'marginTop': '20px'}}>Durations and Deadlines</h2>
                <div className="flex site-block__wrapper column-wrap">
                    <img src={ImageArticle01} className="privacy-warning-img-page leftimg-small"/>
                    <div className='flex-column marginTop20'>
                        <p>Having made the application, it is then subject to review within a period of 60 days. In some cases,
                            the Lithuanian Financial Intelligence Unit (FIU) may extend the review for an additional 60 days.
                            Companies that previously obtained the license are required to comply with the new rules by July
                            1st, 2020, or they can lose their company. Whereas prior Lithuanian licensees needed two licenses,
                            current applicants can make an application for a single license which covers both the Provision of a
                            Service of Exchanging Virtual Currency against a Fiat Currency and the Provision of a Virtual
                            Currency Wallet Service. The former allows exchanging fiat to crypto, crypto to fiat and crypto to
                            crypto while the latter allows for the generation and storage of encrypted keys for customers. Now
                            streamlined, the crypto license allows for the provision of all types of e-wallet and exchange
                            services for virtual currencies.
                        </p>
                    </div>
                </div>





                <div className="flex site-block__wrapper column-wrap">

                    <div className='flex-column marginTop60'>
                        <h2 >The Financial Intelligence Unit</h2>
                    <p>Consideration for the license is not automatic upon submission of the required documents. The
                        Financial Intelligence Unit (FIU) can, and in most cases will, request additional information, or
                        ask for further clarification on a wide range of issues. During this period, traditional law firms
                        tend to add costly fees for time spent working with regulators and rehashing your application.
                        Luckily, Fanbase LLC, the global leader in corporate registrations, includes all negotiations and
                        follow-ups in the flat-rate services.The FIU generally declines an application for two reasons. The first occurs when there is
                        insufficient procedural documentation. The second is when the provision of evidence of a non-
                        criminal record has not been sufficiently detailed. When the applicant is owned by a parent company
                        or when a foreign corporate shareholder cannot provide proof of non-criminality, it poses a
                        logistical problem. While founder backgrounds are generally not difficult to prove, it is quite
                        difficult to prove non-criminality for a registering parent entity, if the new Lithuanian company is a
                        subsidiary. </p>
                    </div>
                    <img src={ImageArticle02} className="privacy-warning-img-page rightFloat"/>
                </div>
                <p className='marginTop20'> Usually, the FIU requests the criminal records of the foreign company, and its members,
                    along with the names of its Ultimate Beneficial Owners (UBO’s). However, in the absence of proof of
                    a non-criminality, a company can provide a statement indicating a clean history from a federal or
                    state registry in the country where the company was originally set-up. Generally, it is safer to
                    avoid the complications of applying for the license using foreign corporate shareholders. When the
                    company is eventually registered and has obtained the license, it qualifies as a financial
                    institution, and can at that time more easily add additional documents, ownership structures, and
                    people. Regardless, foreign entities are not subject to special tax or reporting requirements and
                    accounting is required according to EU and Lithuanian law, just like every other Lithuanian company.
                    Luckily, companies like Fanbase provide this service on a yearly basis, for a flat-rate, in year
                    one, and will renegotiate in year 2, after understanding the scope of the accounting and tax work
                    your company requires. </p>
                <p className="article-p-small">See FIU advisory guidelines <a
                    href='https://www2.politsei.ee/dotAsset/39481.pdf'>https://www2.politsei.ee/dotAsset/39481.pdf </a>
                </p>
                <p className='article-p-margin'>
                    The FIU can also make inquiries on the activities of the company. Where business does not commence
                    within 6 months of obtaining the license, the FIU may revoke the registration, which comes at
                    considerable expense, including time commitment and monetary loss, to the owner. Once any change is
                    made with regards to the registered address of the company, the compliance team, the ownership
                    structure, or the UBO’s, an amendment must be registered with the FIU.

                </p>
                <p className="article-p-small">The revocation of an activity license is addressed in section 37 of the
                    General Part of the Economic Activities Code Act of 2014.
                </p>
                <h2 style={{'marginTop': '20px'}}>
                    FIU Invitations
                </h2>
                <p className="article-p-margin">
                    The FIU has a policy that applies to new applicants as well as companies that want to change their
                    current license. The applicant’s contact person or compliance officer meets with the FIU,
                    face-to-face, in Lithuania. There, the FIU confirms that the representative understands the AML rules,
                    and the relevant EU laws, which deal with money laundering and the financing of terrorism. When the
                    FIU requests a meeting, the licensee has 60 days to set an appointment in Lithuania to meet with the
                    FIU. If she does not make it within the stipulated timeline, a strike against the company is levied
                    and the FIU closes the application. Most licensees bring a translator to the hour long, face-to-face
                    meeting, as FIU representatives generally communicate in the Lithuanian language. If everything checks
                    out, the license will then be issued by the FIU.
                </p>

                <div className="flex site-block__wrapper marginTop40 column-wrap">
                    <div className='flex-column marginTop10'>
                        <h2>
                            Existing Companies with a Financial Activities License
                        </h2>
                        <p className='article-p-margin'>
                            As mentioned earlier, financial institutions and cryptocurrency companies that already have a
                            license are required to comply with the new regulations on July 1st, 2020. The effect of this
                            amendment is that companies must modify their existing documents and submit the updated documents as
                            required. The consequence of not adhering to the new regulations is the revocation of the license.
                            License holders are required to do the following:
                        </p>
                    </div>
                    <img src={ImageArticle03} className="privacy-warning-img-page rightImg-mini"/>

                </div>

                <ol className="privacy_text-ol">
                    <li>Companies must increase their share capital from 2,500€ to 12,000€, paid into a valid EEA bank
                        account.
                    </li>
                    <li>Companies must update their Articles of Association and company profile.</li>
                    <li>Companies must register a place of operation in Lithuania and members of the management board must
                        reside in Lithuania.
                    </li>
                    <li>Companies must update their Anti-Money Laundering procedures to account for the new
                        requirements.
                    </li>
                    <li>Members who are citizens of multiple countries must present copies of non-criminal records and
                        passports from each country.
                    </li>
                    <li>Members must provide the educational profile and CV’s of all members of the company.</li>
                </ol>
                <h2 style={{'marginTop': '20px'}}>
                    Applications by a Subsidiary Company
                </h2>
                <p className='article-p-margin'>A subsidiary is not required to submit an application for a
                    cryptocurrency license in Lithuania. This is because the license of the holding company applies to the
                    subsidiary. Where the holding company has complied with all the conditions, the subsidiary need not
                    increase its share capital. However, the subsidiary is required to provide the CVs, and the
                    non-criminal records of all its members. </p>
                <h2 style={{'marginTop': '20px'}}>
                    Taxation
                </h2>
                <p className='article-p-margin'>Currently, in Lithuania, exchange services and virtual currency
                    transactions are not taxed differently than normal sales transactions, regardless if payments were
                    received in virtual currencies or government issued currencies. Payments of goods and services by
                    means of virtual currencies, therefore, do not vitiate the observance of VAT rules. </p>
                <p><i><b>For more information, contact <a
                    href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a> in
                    Vilnius, Lithuania.</b></i></p>
            </div>

        </div>
    );
};

export default ArticleStartEu;