import './Financing.scss';
import React from 'react';
import Warning from "../../img/partners.jpg";

const Financing = () => {
    return (
        <>
            <div className='about-us'>
                <div className="site-block__wrapper about-us__title">
                    <h1 className='mainTitle'>Financing, Tokenisation & Private Equity
                    </h1>
                </div>

                <div className="privacy_flex-column site-block__wrapper">
                    <h3>LOOKING TO FINANCE YOUR COMPANY?<br/>
                    </h3>
                    <h3>
                        If so, contact <a
                        href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a>.
                    </h3>
                </div>
                <div className="privacy_flex-column site-block__wrapper">
                    <div className="flex site-block__wrapper">
                        <img src={Warning} className="franchise_img"/>
                        <p> Fanbase is the world leader in consultation and document preparation for entrepreneurs
                            seeking to finance their companies through tokenization, crowdfunding or private equity.
                            At Fanbase, we believe that all securities will be tokenized in the not-so-distant future,
                            because tokenization is far superior to traditional methods of financing, equity sharing,
                            and trading. <br/><br/>

                            In no uncertain terms, blockchain technologies are game-changing, and will affect the
                            fintech, media, biotech, banking and gaming industries at their core.
                            Much like the advent of the internet reshaped commerce, the advent of blockchain will affect
                            and improve all payments and securities processes.
                            In the US, EU and across the globe, Fanbase helps companies tokenize their assets so that
                            they can unlock value and tap into public markets.
                            By tokenizing, entrepreneurs can raise funds in efficient ways which avoid middlemen,
                            creditors and banks. And, they can do so in compliant ways.
                        </p>
                        <br/>
                    </div>


                    <br/>
                    <p>
                        Fanbase has emerged as the industry leader because of its wealth of knowledge, flat rate
                        pricing, and partnership with fanbase.io.
                        Our core competency is our ability to execute crowdfunding campaigns and services related to
                        security token offerings, private placements, prospectus drafting,
                        and prospectus registration.
                    </p>
                    <br/>

                    <br/>
                    <p>
                    </p>
                    <br/>
                    <p><b>Initial Coin Offerings</b> were popular in 2017 and 2018.
                        But, their popularity has waned in favor of equity crowdsales. Tokenized equity crowdsales are
                        also known as Security Token Offerings or STOs.
                    </p>
                </div>
                <div className="financing__content">
                    <h4>What is the difference between STO and ICO?</h4>
                    <p>Initial Coin Offerings (ICOs) allowed companies to fundraise by issuing "utility tokens"
                        which were later redeemable for use on the company's platform, much like airline miles.
                        Like airline miles, utility tokens worked like coupons for future use. STO or security
                        token offering is a method of raising capital by issuing tokenized securities. A security
                        token is backed by external, tradable assets, and can represent a real share of the company.
                        Security tokens give you ownership rights, whereas utility tokens are more like a gift card
                        for company products/services.
                    </p>
                    <br/>
                    <h4>What’s the process of doing an STO or an ICO?</h4>
                    <p>The first step is to have a clear and understandable business proposal for the market. You
                        need to be able to write down a decent business plan (a white paper) with the details of the
                        planned activities and the market where you operate in. Once you have this kind of base
                        document prepared, it’s time to engage with us to discuss your plans and build a roadmap for
                        the offering. Any kind of capital raise requires serious commitment of time and resources,
                        and a very strong value proposition to succeed.
                    </p><br/>
                    <h4>How are your services priced?</h4>
                    <p>From the legal perspective, doing a security token offering is more demanding and the costs
                        that will occur are considerably higher compared to costs that will occur by conducting an
                        ICO. The pricing depends on the services required, to what extent the client can provide
                        necessary input, and whether we’ll include a success fee as part of the pricing structure.
                        Details are given upon engaging with the client and learning about the project.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Financing;
