import './PageNotFound.scss';
import React from 'react';

const PageNotFound = () => {
    return (
        <div className='page-not-found__wrapper'>
            <h1>Error 404</h1>
            <p>Woops. Looks like page doesn't exist.</p>
        </div>
    );
};

export default PageNotFound;