import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/tip7.jpg'

const ArticleTips7 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>THE FUTURE:
                    BLOCKCHAIN, FINTECH & MUSIC

                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>20th August, 2020
                    <br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com
                    </a>
                </h3>
            </div>
            <div className="flex site-block__wrapper ">
                <img src={ImageArticle} className="privacy-warning-img-page leftimg"/>
                <div className='flex-column'>
                    <p className='article-p-margin'>Financial and blockchain technologies are changing the world in
                        dynamic ways. Those changes are fast- paced and cut across virtually every sector,
                        revolutionizing finance, and making it possible for people to access money services who
                        previously only had access to local currencies and tangible forms of trade. The change to
                        digital currencies has facilitated unique experiences in the gaming sector and is similarly
                        projected to have large scale impacts in the music business. Specifically, key changes, due to
                        advancements from fintechs that harness blockchain technologies and cryptocurrencies, will
                        significantly affect music creators and owners of music rights.
                        <br/><br/>
                        In the last 100 years of recorded music, artists had to rely on record labels, promoters and
                        distributors to share their artistry with larger audiences and to plan and sell those
                        connections with their fans; an alluring deal for most early stage creators which came at a
                        high, long term price. As a matter of fact, this process, to a large extent, put control of
                        artist revenues in the hands of management companies and record labels, homogenizing music for
                        the sake of profitability and making it possible for an artist to be easily controlled. In some
                        cases, unfair compensation and poor management processes have rendered artists mere shadows of
                        their former selves, choosing to resign from the spotlight altogether rather than fight or sue
                        their way out of bureaucratic nightmares. </p>

                   
                </div>
            </div>
            <div className="privacy_flex-column site-block__wrapper">

                <p className='article-p-margin'>
                    However, with the digitisation of music, the democratisation of music sharing on spaces like
                    youtube, and key advancements in blockchain and financial technologies, prospects for independent
                    music creators are improving. This is true because technologies now allow artists to promote and
                    sell digital products while controlling their careers all by themselves- an alluring proposition
                    that comes with creative and financial advantages. In some cases, on platforms like fanbase.io,
                    artists now securitize and sell ‘stock’ in their albums, prior to, or following the album’s
                    completion, via digital tokens which represent securities, ownership stakes in the work, or its
                    royalties. Importantly, on these platforms, artists now choose their own contract terms and lengths,
                    and are bargaining with adoring fans from positions of authority, as opposed to positions of
                    subordination in relation to large media organizations. In most cases artists choose contracts which
                    allow them to get their royalty shares back, at the conclusion of their sharing obligations, which
                    they themselves have selected in their royaltyshare contracts.

                </p>
                <p className='article-p-margin'>For artists, independence is now more possible than ever, because there
                    are opportunities for artists to distribute their own music via services like iTunes, Spotify, and
                    YouTube and because blockchain and cryptocurrencies now facilitate crowdfunding and crowd-sharing
                    scenarios for those artists to raise the money to produce and promote their albums. Because of
                    platforms like fanbase.io, the music industry is gradually seeing a transition to more flexible,
                    artist-centered outcomes for creators and fans in large part because blockchain transactions,
                    conducted on public ledgers, are more transparent, inclusive and accessible across the globe as
                    music products and as digital securities unbound by national borders.
                </p>


                <p>The precursor to the distributed ledger system used now by companies like fanbase.io for music and
                    music financing, was the Bitcoin distributed ledger. But, since its invention in 2009, there have
                    been many derivative blockchains which improve upon Bitcoin’s original architecture. The most
                    prominent is the Ethereum network, of which fanbase.io has partnered and developed its own
                    self-hosted private chain, allowing it to conduct blockchain transactions at near zero costs to
                    consumers, whereas Ethereum mainnet transactions can have high costs to consumers and slower
                    transaction times due to congestion. </p>
                <br/>
                <p>Generally, distributed ledgers are databases which are publicly viewable, but not owned by any single
                    authority, so the systems function transparently because the data on the blockchain is unchangeable
                    once created. This means that the proprietary rights and interests of music artists cannot be
                    altered or manipulated and the value does not need middlemen to be digitally transferred. In other
                    words, decentralized databases are crucial for music artists because they maintain a chain of
                    ongoing transactions which are traceable by the artist. </p>

                <br/>
                <p>For example, if someone purchases an album, or an artist transfers his proprietary rights in a
                    musical work to someone else, the record of that transaction is instantly recorded on the public
                    ledger blockchain. This provides clarity and closure on intellectual property sales and transparency
                    between artists and partners without the need for middlemen. It also includes an artist’s fans in
                    the commercial and promotional aspects of the production pipeline because fans can promote the work
                    online and take on the collective role of a label.</p>  <br/>
                    
                    <p>A former Media Studies professor, the current CEO of fanbase.io, believes “the general public
                    will gain a more nuanced understanding of market forces and a broader perspective on cultural
                    products, as they more closely examine the economics of artistry as opposed to just the
                    entertainment or personal value of songs.” In other words, by owning ‘shares’ of albums or digital
                    works, in general, fans will more closely examine the strengths and weaknesses of their favorite
                    artists and the commercial and cultural forces which drive that artist’s success. </p><br/>
                    
                    <p>“I think the
                    result will be smarter, more engaged fandom, although I do fear what might happen to artists who
                    take on investment but do not produce commercially viable products- fandom is already an emotionally
                    intense experience,” he explained. “In some cases, artists who take on fan investors might long for
                    the days when labels buffered their experiences with fans. So, there is no ‘one-size fits all’
                    approach, even with all the amazing affordances of blockchain."</p> <br/>


                <p className='article-p-margin'>He concluded, "At the end of the day, art is commercial- even Shakespearre was writing
                    to put butts in seats, and it takes a production team to facilitate that. So, while blockchain
                    ledgers can help artists understand the ways their musical works are being used and remove huge roadblocks to financing, artists will still require myriad
                    support for tours, collaborations, bookings, and the like. As a general rule, however, the
                    freedoms that blockchain transactions afford are something that almost all artists clamour for."</p> <br/>
                    
                    <p>
                </p>
                <p><i><b>If you are a artist who needs help with a crowdsale, contact <a
                    href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a>.</b></i></p>
            </div>

        </div>
    );
};

export default ArticleTips7;