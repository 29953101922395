import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const PowerAttorney = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Power of Attorney
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>DO YOU NEED POWER OF ATTORNEY DOCUMENTS?<br/>
                <p>If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help...'> dex@fanbaseconsulting.com</a>.</p>
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>Power of Attorney (POA) is a legal document which gives one person the right to act on the behalf of another person, on a number of matters that range from business affairs to personal health issues. There are four types of Power of Attorney documents. 
                    A General POA grants power to a person to act on your behalf when it comes to running your business. 
                    A Special POA grants power to another person because you cannot perform your usual duties due to other commitments or health reasons. 
                    A Health Care POA is when you are unconscious or have become mentally impaired but have passed on powers to a person to make medical decisions on your behalf. 
                    Lastly, a Durable POA grants a safeguard against mental impairment through an accident or illness and keeps the current POA in effect.
                    <br/><br/>At Fanbase, we have the adequate legal staff to provide this service to you. Contact us for a consultation about which POA would be ideal for you. Most often, we write General POA documents for our customers to provide their business with quick and efficient legal representation in the EU, however we can handle other POA matters.
                    </p>
                    

               
                    <br/>

               
                
                <p><i><b>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help writing a white paper...'> dex@fanbaseconsulting.com</a> for help with Power of Attorney documents.</b></i></p>
            </div>

        </div>
    );
};

export default PowerAttorney;
