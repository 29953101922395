import '../AboutUs/AboutUs.scss';
import './PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'

const PrivacyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='about-us'>
                <div className="site-block__wrapper about-us__title">
                    <h1 className='mainTitle'>Privacy Policy
                    </h1>
                </div>
                <div className="privacy_flex-column site-block__wrapper">
                    <h3>NEED HELP WRITING A PRIVACY POLICY?<br/>
                        <p>If so, contact <a
                            href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need GDPR help.'> dex@fanbaseconsulting.com</a>.
                        </p>
                    </h3>
                </div>
                <div className="privacy_flex-column site-block__wrapper privacy-block">
                


            <div className="privacy_flex-column site-block__wrapper">
                <p>A Privacy Policy is a legal notice mandated through GDPR regulations which details the specific ways your business will gather, use and manage customer data. The Privacy Policy adds an air of legitimacy to your business as it reassures customers that giving you their personal information will not result in the illicit sale or exploitation of that data.
                    Fanbase assists clients with Privacy Policies.  
                    <br/> <br/>
                    It is important that our clients follow GDPR guidelines and do not fall victim to any legal turmoil. We ensure that your Privacy Policy is comprehensive, accurate and unambiguous so that when customers use your service it is clear when a third party provider, rather than your company, is responsible for the safe and private care of personal data.

                </p>
                <br/>

                <p><i><b>Contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help writing a privacy policy...'> dex@fanbaseconsulting.com</a> for help with your Privacy Policy.</b></i></p>
            </div>

        </div>
                <div className="privacy_flex-column site-block__wrapper privacy-block">


                    
                   
                </div>
            </div>

        </>


    );
};

export default PrivacyPage;

