import './License.scss';
import React from 'react';
import CallbackForm from "../../../../Components/CallbackForm/CallbackForm";

const License = () => {
    return (
        <>
        <div className='home__license'>

            <div className="home__license__info">
                <span>For EU Trusts & Company Services</span>
                <h2 className='mainTitle'>Licensed</h2>
                <p> Fanbase is licensed in the European Union.
                    However, we are not limited to only providing services to EU companies.  We can assist with US Money Service Businesses,
                    Money Transmitter Licenses, Broker-Dealers, Crowdfunding Portals, Company Registrations, Trademarks and more.
                </p>
            </div>

        </div>  <CallbackForm/>
            </>
    );
};

export default License;
