import '../AboutUs/AboutUs.scss';
import '../Privacy/PrivacyPage.scss';
import React, {useEffect} from 'react';
import Warning from '../../img/warning.png'
import {Link} from "react-router-dom";

const Registration = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>Registration
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>LOOKING TO INCORPORATE A COMPANY?<br/>
                If so, contact <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help'> dex@fanbaseconsulting.com</a>.
                </h3>
            </div>
            <div className="privacy_flex-column site-block__wrapper">
                

            </div>
            <div className="privacy_flex-column site-block__wrapper">
                <p>If you are unsure where to incorporate your company, or if you have identified your optimal jurisdiction and just need help applying, contact Fanbase.  
                    Fanbase is the world leader in corporate registrations with a footprint in jurisdictions across the globe. 
                    <br/> <br/>We specialize in helping banking, fintech, biotech and media companies and will help you choose the optimal jurisdiction to incorporate your company.
                    Generally, start-up crypto companies choose Lithuania as their place of incorporation. Start-up payment service providers often choose Lithuania. Start-up banking entities often choose Puerto Rico or Wyoming, and start-up Broker-Dealers generally choose British Virgin Islands.
                    <br/> <br/><i><b>For a free business consultation, contact<a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I need help registering a company...'> dex@fanbaseconsulting.com</a></b></i>. We can help you choose a jurisdiction or register your company.

                </p>
        
                
            </div>

        </div>
    );
};

export default Registration;
