import '../../AboutUs/AboutUs.scss';
import '../articles.scss';
import React, {useEffect} from 'react';
import ImageArticle from '../../../img/articles/tip5.jpg'
import ImageArticle01 from '../../../img/articles/tip4-1.jpg'



const ArticleTips5 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='about-us'>
            <div className="site-block__wrapper about-us__title">
                <h1 className='mainTitle'>TIP #5: INCORPORATE YOUR BANK IN PUERTO RICO
                </h1>
            </div>

            <div className="privacy_flex-column site-block__wrapper">
                <h3>28th July, 2020<br/>
                    by <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, Serene'>dex@fanbaseconsulting.com</a>
                </h3>
            </div>
            <div className="flex site-block__wrapper">
                <img src={ImageArticle} className="leftimg"/>
                <p>Puerto Rico is a carribean island territory bordered by the US Virgin Islands. Since 2012, it has
                    garnered the serious attention of banks and financial service entities, like US broker-dealers, as
                    an attractive regulatory environment free from US federal taxation. While financial institutions in
                    Puerto Rico are multiplying rapidly, they are still strictly regulated by the Office of the
                    Commissioner of Financial Institutions (OCIF) which is in turn regulated by the US Federal Reserve.
                    Generally, the Puerto Rican jurisdiction is extremely attractive to international banks because they
                    enjoy access to US markets while also avoiding US taxes. Pfizer International Bank and Santander
                    Overseas Bank are two global institutions, incorporated in Puerto Rico, among dozens of other global
                    banking corporations also registered there. While different banking jurisdictions come with their
                    own affordances and constraints, Puerto Rican International Financial Entities (IFE's) offer
                    significant economic and strategic advantages, even for smaller scale banking operations and de novo
                    banking institutions. In fact, in most cases, Fanbase LLC, the global leader in corporate banking
                    registration, recommends that organizations consider Puerto Rico as a destination for their new bank
                    entity.
                </p>

            </div>
            <div className="privacy_flex-column site-block__wrapper margin-top-0">
                <p className='marginTop10'> There are two key reasons why Puerto Rico is advantageous. First, as
                    mentioned, as an unincorporated US territory, Puerto Rico governs and regulates itself in matters of
                    taxation. This means that Puerto Rico can sponsor programs to lure foreign investors, such as a four
                    percent local tax for International Financial Entities, and zero percent federal tax. Such waivers
                    on local taxes entice CEOs to relocate their financial entities, as there are scarcely any external
                    interferences related to taxation, from US regulators, in terms of the policies that apply to Puerto
                    Rican IFEs. The benefits from tax treaties are possible because offshore jurisdictions are legatees
                    of the Double Taxation Treaties Agreement (DTTA) which provides for predictable business
                    environments. Act 273, officially known as the International Financial Center Regulatory Act,
                    encodes into law the tax exemptions for foreign investors who intend to carry out registered
                    financial activities in Puerto Rico. And, the Act empowers the OCIF to grant International Banking
                    Licenses to applicants who meet the legal requirements.
                </p>


                <p className="article-p-margin">
                    Second, US economic and fiscal policies shape the region, and in some senses, the globe, because
                    without a correspondent US bank, international banks cannot effect transactions in US dollars.
                    Dollars remain the world reserve currency due to the strength and size of the United States economy
                    as a whole, which makes Puerto Rican IFE start-ups better than Central America banking start-ups,
                    simply because IFEs have better access to USD. In other words, most banks need access to USD because
                    global transactions and large scale purchases are settled in US dollars. Generally, when a bank
                    suffers the loss of a correspondent, particularly a US correspondent, the bank funds are frozen and
                    its financial activities are halted, which is less likely to happen to banks incorporated in Puerto
                    Rico.

                </p>
                <p className="article-p-margin">
                    Stories of banking gone wrong, abound. For example, in 2010, the Central Bank of Belize lost its
                    correspondent relationship with HSBC Bank when HSBC terminated all foreign currency cash operations
                    in Central America. Later, Caye International Bank experienced a similar fate. Generally, a
                    tightening occurs when US regulators identify suspicious activity related to money laundering or the
                    funding of terrorism that is ignored or overlooked by banks on a systemic level. The great thing
                    about Puerto Rican IFEs is that, for all intents and purposes, IFEs are US banks innate to the US
                    system; therefore correspondence cannot as easily be terminated. Puerto Rico identifies itself as a
                    “free associated state” because it has some freedoms from taxation and federal governance but
                    maintains some advantages of statehood. It is an offshore banking destination with some onshore
                    perks!
                </p>
                <div className="flex site-block__wrapper column-wrap">
                    <div className='flex-column'>
                        <p className="marginTop10">To incorporate your bank in Puerto Rico you will need to raise about
                            2.5 million dollars total. To incorporate in the United States, you will need to raise 3 to
                            5 times that. For an extensive read on the legal requirements associated with owning an
                            International Financial Entity in Puerto Rico, refer to our article titled “Tip #6: Steps to
                            Incorporate your Bank in Puerto Rico”.
                        </p>
                        <p className='marginTop20'><i><b>Need help with Registering a Bank in the US or PR? Contact <a
                            href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'> dex@fanbaseconsulting.com</a> in
                            the US.</b></i></p>
                    </div>
                    {/*<img src={ImageArticle01} className="rightImg-small "/>*/}
                </div>
            </div>
        </div>
    );
};

export default ArticleTips5;