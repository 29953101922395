import React, {useEffect} from 'react';
import './Home.scss';
import Greetings from "./Blocks/Greetings/Greetings";
import Jurisdictions from "./Blocks/Jurisdictions/Jurisdictions";
import Services from "./Blocks/Services/Services";
import ServiceCategories from "./Blocks/ServiceCategories/ServiceCategories";
import License from "./Blocks/License/License";
import Global from "./Blocks/Global/Global";
import Comments from "./Blocks/Comments/Comments";
import Business from "./Blocks/Business/Business";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='home__wrapper'>
            <Greetings/>
            <Jurisdictions/>
            {/*<Services/>*/}
            <ServiceCategories/>
            <License/>
            <Global/>
            <Comments/>
            <Business/>
            <h3 className='home__end-question'>
                The Premier Partner for Technology, Media, Finance & Crypto Companies
                Looking to Register, License and Bank in the EU or US.
            </h3>
        </div>
    );
};

export default Home;
