import './Header.scss';
import logo from '../../img/Fanbase_logo.png';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Collapse, Input, Menu} from "antd";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import ArrowUpOutlined from "@ant-design/icons/lib/icons/ArrowUpOutlined";
import searchLinkTool from '../Search/SearchTool';

const {SubMenu} = Menu;
const {Search} = Input;
const {Panel} = Collapse;

const Header = () => {
    const location = useLocation();
    const [showSearch, setShowSearch] = useState(false);
    const [searchResult, setSearchResult] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    console.log('searchResult',searchResult)

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (document.documentElement.clientWidth > 1100) {
                setShowMobileMenu(false)
            }
        });

        return window.removeEventListener('resize', () => {
        })
    }, []);

    const [currentPage, setCurrentPage] = useState('');

    useEffect(() => {
        setCurrentPage(location.pathname.substring(1) || 'home')
    }, [location.pathname]);


    //
    // old search
    // const searchLink = (value) => {
    //     let promise = new Promise(function (resolve, reject) {
    //         let coincidence = [];
    //         searchData.map((item) => {
    //             let dataContent = item.content;
    //             if (dataContent.includes(value)) {
    //                 coincidence.push({link: item.link, name: item.name});
    //             }
    //         });
    //         resolve(coincidence);
    //         reject(err => console.log('error', err))
    //     });
    //
    //     promise
    //         .then(
    //             result => setSearchResult(result),
    //             error => {
    //                 console.log("Rejected: " + error); // error
    //             }
    //         );
    //
    //
    // };


    return (
        <>
            <div className='header__wrapper'>
                {/*
                    //  Commented out by decree of Ron
                    <div className="header__contacts">
                    <div className="header__contacts__regular">
                        <div className='header__contacts__regular__item'>
                            <PhoneFilled/>
                            <p> Telegram App: +41 78 9555185</p>
                        </div>
                        <div className='header__contacts__regular__item'>
                            <MailFilled/>
                            <p>info@codipity.com</p>
                        </div>
                    </div>
                    <div className="header__contacts__social">
                        <TwitterOutlined/>
                        <FacebookFilled/>
                        <InstagramFilled/>
                    </div>
                </div>*/}
                <div className="header__menu">
                    <Link to='/'><img src={logo} alt="logo"/></Link>
                    <div className='header__menu__list__wrapper'>
                        <div className="header__menu__list">
                            <Menu mode="horizontal" selectedKeys={[currentPage]}>


                                <SubMenu title={<p>Services <RightOutlined/></p>}>
                                    <Menu.Item key="careerManagement">
                                        <Link to='/accounting'>Accounting</Link>
                                    </Menu.Item>
                                    <Menu.Item key="careerManagement">
                                        <Link to='/registration'>Registrations</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Individuals">
                                        <Link to='/freelacencers'>Freelancers</Link>
                                    </Menu.Item>
                                </SubMenu>

                                <SubMenu title={<p>Documents <RightOutlined/></p>}>
                                    {/*<SubMenu title='Documents'>*/}
                                    <Menu.Item key="careerManagement">
                                        <Link to='/artist'> Artist Contracts</Link>
                                    </Menu.Item>
                                    <Menu.Item key="AML">
                                        <Link to='/aml'>AML & KYC</Link>
                                    </Menu.Item>
                                    <Menu.Item key="GDPR">
                                        <Link to='/gdpr'>GDPR</Link>
                                    </Menu.Item>
                                    <Menu.Item key="WhitePapers">
                                        <Link to='/white-papers'>White Papers</Link>
                                    </Menu.Item>
                                    <Menu.Item key="BusinessPlans">
                                        <Link to='/business-plan'>Business Plans</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Financing">
                                        <Link to='/financing'>Financing (Reg D, etc.)</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Registrations">
                                        <Link to='/company-registration'>Company Registrations</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Terms">
                                        <Link to='/terms'>Terms of Service</Link>
                                    </Menu.Item>
                                    <Menu.Item key="PrivacyPolicies">
                                        <Link to='/privacy'>Privacy Policies</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Private Placement">
                                        <Link to='/private-placement'>Private Placements</Link>
                                    </Menu.Item>
                                    <Menu.Item key="PowerOfAttorney">
                                        <Link to='/power-attorney'>Power of Attorney</Link>
                                    </Menu.Item>
                                    <Menu.Item key="SAFT">
                                        <Link to='/saft'>SAFT</Link>
                                    </Menu.Item>
                                    {/*</SubMenu>*/}
                                </SubMenu>
                                <Menu.Item key="partnerships" onClick={e => setCurrentPage(e.key)}>
                                    <Link to='/partnerships'>Partnerships</Link>
                                </Menu.Item>


                                <Menu.Item key="contact">
                                    <a href='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'>Contact</a>
                                </Menu.Item>
                                <Menu.Item key="about" onClick={e => setCurrentPage(e.key)}>
                                    <Link to='/about'>Team</Link>
                                </Menu.Item>
                                <Menu.Item key="blog" onClick={e => setCurrentPage(e.key)}>
                                    <Link to='/blog'>Expert Articles</Link>
                                </Menu.Item>

                            </Menu>
                        </div>
                        <div style={{display: 'flex'}}>
                            {!showSearch
                                ? <SearchOutlined onClick={() => setShowSearch(!showSearch)}/>
                                : <>
                                    <CloseOutlined onClick={() => {
                                        setShowSearch(!showSearch);
                                        setSearchResult(false)
                                    }}/>
                                    <div style={{"position": "relative"}} className='search_container'>
                                        <Input placeholder="Search..."
                                                onChange={value => value.target.value ? searchLinkTool(value.target.value, setSearchResult) : setSearchResult([])}
                                                className='header__menu__search'
                                        />

                                        {searchResult &&
                                        <div className='flex-column search_result'>{
                                            searchResult.map((searchResult, idx) =>
                                                <div key={idx} className="search_result_line">
                                                    <Link to={searchResult.link} onClick={() => {
                                                        setSearchResult(false);
                                                        setShowSearch(false)
                                                    }}>{searchResult.name}</Link>
                                                </div>
                                            )}
                                        </div>}
                                    </div>

                                </>
                            }
                            {!showMobileMenu
                                ? <MenuOutlined className='header__mobile-menu__icon'
                                                onClick={() => setShowMobileMenu(!showMobileMenu)}/>
                                : <ArrowUpOutlined className='header__mobile-menu__icon'
                                                   onClick={() => setShowMobileMenu(!showMobileMenu)}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='header__mobile-menu' style={{display: showMobileMenu ? 'block' : 'none'}}>
                <Link to='/' onClick={() => setShowMobileMenu(false)}>Home</Link>
                <Link to='/about' onClick={() => setShowMobileMenu(false)}>Team</Link>
                <Collapse>
                    <Panel header="Services" key="Services" showArrow={false}>
                        <Collapse ghost>
                            <Panel header="Advisory" key="Advisory" showArrow={false}>
                                <Link to='/aml_gdpr' onClick={() => setShowMobileMenu(false)}>
                                    AML & GDPR
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Blockchain
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Menu
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Financing (tokenisation)
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Strategic Management
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Business Development
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Business Therapist
                                </Link>


                            </Panel>
                        </Collapse>
                        <Collapse ghost>
                            <Panel header="Legal Services" key="LegalServices" showArrow={false}>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Capital Markets
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Mergers and Acquisitions
                                </Link>
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Tax
                                </Link>
                            </Panel>
                        </Collapse>
                        <Collapse ghost>
                            <Panel header="Management" key="Management">
                                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                                    Corporate Support Services
                                </Link>
                            </Panel>
                        </Collapse>
                    </Panel>
                </Collapse>
                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                    Business
                </Link>
                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                    Contact
                </Link>
                <Link to='/' onClick={() => setShowMobileMenu(false)}>
                    Information
                </Link>
                <Link to='/partnerships' onClick={() => setShowMobileMenu(false)}>
                    Partnerships
                </Link>
                <Link to='/blog' onClick={() => setShowMobileMenu(false)}>
                    Expert Articles
                </Link>
                <Link to='mailto:dex@fanbaseconsulting.com?subject=Hello, I have a question'
                      onClick={() => setShowMobileMenu(false)}>
                    Contact
                </Link>
            </div>
        </>
    );
};

export default Header;
